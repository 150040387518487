<script>
import { processNumbers, formatNumber, addSpace } from '@/@core/utils'
import ProgressLoading from '@/components/ProgressLoading/index.vue'
import { statusPointColorMap } from '@/@core/utils/colorMap'
import Pagination from '@/components/Pagination.vue'
import { useMessage } from '@/hooks/useMessage'
import { downloadFile } from '@/@core/utils/form'
import SuperTable from '@/components/SuperTable.vue'
import moment from 'moment'
import { ref, reactive, onMounted, nextTick, computed } from '@vue/composition-api'
import AppView from '@/components/AppView.vue'
import SearchForm from '@/components/SearchForm/index.vue'
import ImportFailDialog from './importFailDialog.vue'
import BatchAdjustDialog from './batchAdjustDialog.vue'
import mapEnum, { channelTypeMap } from '../mapEnum'
import { reportCid, reversalList, reversalExport, reversalDelete, reversalRegenerate, joinUnreceived, reversalImport } from '../service'
import { tableColumn2 } from '../tabCols'
import Subset from './subset.vue'
import Adjust from './adjust.vue'
import NotListDialog from './notListDialog.vue'
import createFinalDialog from './createFinalDialog.vue'

export default {
  name: 'RunCell',
  components: { AppView, SearchForm, SuperTable, Subset, Adjust, Pagination, createFinalDialog, NotListDialog, BatchAdjustDialog, ProgressLoading, ImportFailDialog },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    tab: {
      type: String,
      default: '',
    },
    activeName: {
      type: String,
      default: 'cx',
    },
  },
  setup(props) {
    const form = ref()
    const table = ref()
    const loading = ref(false)
    const { message, MessageBox } = useMessage()

    const searchForm = computed(() => props.formData).value

    const times = computed(() => ({
      monthStart: searchForm.month[0],
      monthEnd: searchForm.month[1],
    }))

    const page = reactive({
      page: 1,
      pageSize: 20,
      total: 0,
    })
    const revenueForm = reactive({
      info: {
        type: 'reversal',
        id: undefined,
        title: undefined,
        channelId: undefined,
      },
      list: {
        cmsRevenueAdjust: undefined,
        cmsRevenueAdjustRemark: undefined,
        cmsRevenueSgAdjust: undefined,
        cmsRevenueSgAdjustRemark: undefined,
        cmsRevenueUsAdjust: undefined,
        cmsRevenueUsAdjustRemark: undefined,
      },
    })
    const searchConfig = ref([
      {
        el: 'DateMonthRange',
        props: 'month',
        label: '月份',
        type: 'month',
        clearable: false,
      },
      {
        el: 'VTextField',
        props: 'title',
        label: '频道名称',
      },
      {
        el: 'VTextField',
        props: 'channelId',
        label: '频道ID',
      },
      {
        el: 'VSelect',
        props: 'cms',
        label: '收款系统',
        items: mapEnum.cmsOptions,
        itemText: 'label',
        itemValue: 'value',
      },
      {
        el: 'VSelect',
        props: 'channelType',
        label: '频道类型',
        items: mapEnum.channelTypeOptions,
        multiple: true,
        itemText: 'label',
        itemValue: 'value',
      },
      {
        el: 'VTextField',
        props: 'subsetName',
        label: '子集名称',
      },
      {
        el: 'VSelect',
        props: 'settlementCreatedStatus',
        label: '结算单生成状态',
        items: mapEnum.statusOptions,
        itemText: 'label',
        itemValue: 'value',
      },
    ])
    const visibleAdjust = ref(false)
    const visibleSubset = ref(false)
    const visibleCreateFinal = ref(false)
    const visibleNotList = ref(false)
    const visibleBatchAdjust = ref(false)
    const visibleLoading = ref(false)
    const visibleImportFail = ref(false)

    const cidForm = ref({})
    const tableData = ref([])
    const selected = ref([])
    const getCidList = () => {
      reportCid(times.value)
        .then(({ data }) => {
          cidForm.value = (data.data || {})
        })
        .catch(error => message.error(error.response.data.message))
    }

    const init = () => {
      loading.value = true
      reversalList({ ...page, total: undefined, ...searchForm, ...times.value, month: undefined, channelType: props.tab === 'cx' ? searchForm.channelType.join(',') : searchForm.channelType }).then(({ data }) => {
        loading.value = false
        selected.value = []
        table.value.clear()
        tableData.value = formatNumber(data.data, tableColumn2)
        page.total = data.total
      }).catch(error => {
        loading.value = false
        tableData.value = []
        page.total = 0
        message.error(error.response.data.message)
      })
    }

    const search = () => {
      page.page = 1
      init()
      getCidList()
    }

    const reset = async () => {
      await form.value.resetForm()
      nextTick(() => {
        searchForm.month = [moment().subtract(1, 'month').format('yyyy-MM'), moment().subtract(1, 'month').format('yyyy-MM')]
        init()
      })
    }

    // 拆分
    const split = item => {
      visibleSubset.value = true
      revenueForm.info.id = item.id
      revenueForm.info.channelId = item.channelId
      revenueForm.info.title = item.channelName
    }

    // 调差
    const adjustDifference = item => {
      revenueForm.info.id = item.id
      revenueForm.info.title = item.channelName
      visibleAdjust.value = true
      const {
        cmsRevenueAdjust,
        cmsRevenueAdjustRemark,
        cmsRevenueSgAdjust,
        cmsRevenueSgAdjustRemark,
        cmsRevenueUsAdjust,
        cmsRevenueUsAdjustRemark,
      } = item
      const newList = {
        cmsRevenueAdjust,
        cmsRevenueAdjustRemark,
        cmsRevenueSgAdjust,
        cmsRevenueSgAdjustRemark,
        cmsRevenueUsAdjust,
        cmsRevenueUsAdjustRemark,
      }
      Object.assign(revenueForm.list, newList)
    }

    const del = id => {
      MessageBox({
        message: '确认删除？',
        type: 'warning',
        showCancelButton: true,
        cancelBtnText: addSpace('取消'),
        confirmBtnText: addSpace('确定'),
      })
        .then(() => {
          reversalDelete(id)
            .then(() => {
              message.success('删除成功')
              init()
            })
            .catch(error => message.error(error.response.data.message))
        })
        .catch(error => error)
    }

    // 表格背景色
    const conditions = [
      { indexRange: [0, 6], color: 'rgba(145,85,253,0.04)' },
      { indexRange: [6, 12], color: 'rgba(44,160,248,0.04)' },
      { indexRange: [12, 23], color: 'rgba(255,168,15,0.04)' },
      { indexRange: [23, 37], color: 'rgba(0,176,155,0.04)' },
    ]
    const headerCellStyle = ({ columnIndex }) => {
      const matchedCondition = conditions.find(({ indexRange }) => {
        const [startIndex, endIndex] = indexRange

        return columnIndex >= startIndex && columnIndex < endIndex
      })

      // 如果找到匹配的条件，则返回对应的样式，否则返回默认样式
      return matchedCondition ? { backgroundColor: matchedCondition.color } : {}
    }
    const cellStyle = ({ columnIndex }) => {
      const matchedCondition = conditions.find(({ indexRange }) => {
        const [startIndex, endIndex] = indexRange

        return columnIndex >= startIndex && columnIndex < endIndex
      })

      // 如果找到匹配的条件，则返回对应的样式，否则返回默认样式
      return matchedCondition ? { backgroundColor: matchedCondition.color } : {}
    }

    // 导出Excel
    const exportExcel = () => {
      message.info('导出中...请稍等')
      reversalExport({ ...page, total: undefined, ...searchForm, ...times.value, month: undefined })
        .then(data => {
          const fileNameDownload = `在 ${new Date().toLocaleString().replaceAll('/', '-')}导出的冲销报表`
          downloadFile(data.data, fileNameDownload)
        })
        .catch(error => message.error(error.response.data.message))
    }

    const checkBoxChange = ({ records }) => {
      selected.value = records
    }

    const selectAllEvent = ({ records }) => {
      selected.value = records
    }

    const dialogSubmit = () => {}
    /**
     * 批量重新生成
     */
    const batchGenerate = () => {
      if (!selected.value.length) return message.warning('请至少选择一条数据')
      const newArr = selected.value
        .filter(item => item.channelType === 1 || !item.channelSplitStatus)
        .map(item => item.id)
      MessageBox({
        title: '重新生成',
        message: `已选择 ${selected.value.length} 条，${newArr.length} 条可重新生成，是否确认重新生成？`,
        type: 'warning',
        showCancelButton: true,
        cancelBtnText: addSpace('取消'),
        confirmBtnText: addSpace('确定'),
      })
        .then(() => {
          if (!newArr.length) return message.warning('没有可重新生成的数据')
          reversalRegenerate(newArr)
            .then(({ data }) => {
              if (data.status === 200) message.success('重新生成成功')
              init()
            })
            .catch(error => message.error(error.response.data.message))
        })
        .catch(error => error)
    }

    /**
     * 生成结算单
     */
    const createFinal = () => {
      if (times.value.monthStart === times.value.monthEnd) {
        visibleCreateFinal.value = true
      } else {
        message.warning('请选择同一月份')
      }
    }
    /**
     * 未到账列表
     */
    const notList = () => {
      visibleNotList.value = true
    }
    /**
     * 加入未到账
     */
    const joinNot = () => {
      if (!selected.value.length) return message.warning('请至少选择一条数据')

      const flag = selected.value.some(item => [channelTypeMap.compilation, channelTypeMap.singleOpen].includes(item.channelType))
      if (!flag) return message.warning('请选择单开/合集数据')
      console.log(flag)
      const ids = selected.value.map(item => item.id)
      joinUnreceived({ ids }).then(({ data }) => {
        console.log(data)
        if (data.status === 200) message.success('操作成功')
        init()
      }).catch(error => message.error(error.response.data.message))
    }

    /**
     * 批量调差
     */
    const batchAdjustDifference = () => {
      if (times.value.monthStart === times.value.monthEnd) {
        visibleBatchAdjust.value = true
      } else {
        message.warning('请选择同一月份')
      }
    }

    const upPercentage = ref(0)
    const failList = ref([])
    /**
     * 批量调差提交
     * @param {*} val
     */
    const batchSubmit = key => {
      console.log(key)
      MessageBox({
        message: '导入后，对应频道的调差数据将会覆盖，是否继续？',
        type: 'warning',
        showCancelButton: true,
        cancelBtnText: addSpace('取消'),
        confirmBtnText: addSpace('确定'),
      }).then(() => {
        visibleLoading.value = true
        reversalImport({}, encodeURIComponent(key), times.value.monthStart, progressEvent => {
          upPercentage.value = Math.round((progressEvent.loaded / progressEvent.total) * 100)
        }).then(({ data }) => {
          visibleLoading.value = false
          if (data.status === 200) {
            message.success('导入成功')
            visibleBatchAdjust.value = false
          }
        }).catch(error => {
          visibleLoading.value = false
          const { data } = error.response
          if (data.data.length) {
            console.log('上传错误，但存在错误信息')
            failList.value = data.data
            visibleImportFail.value = true
          }
          message.error(error.response.data.message)
        })
      })
    }

    function handleCurrentChange(val) {
      page.page = val
      init()
    }

    function handleSizeChange(val) {
      page.page = 1
      page.pageSize = val
      init()
    }

    function filterMap(key, val) {
      const arr = mapEnum[key] || []
      const data = arr.find(item => item.value === val)

      return data ? data.label : val || '-'
    }

    onMounted(() => {
      if (props.activeName === props.tab) {
        init()
        getCidList()
      }
    })

    return {
      form,
      table,
      visibleAdjust,
      visibleSubset,
      visibleCreateFinal,
      visibleNotList,
      searchConfig,
      searchForm,
      tableColumn2,
      tableData,
      page,
      revenueForm,
      cidForm,
      reset,
      search,
      del,
      init,
      split,
      batchGenerate,
      dialogSubmit,
      exportExcel,
      cellStyle,
      headerCellStyle,
      adjustDifference,
      checkBoxChange,
      selectAllEvent,
      handleCurrentChange,
      handleSizeChange,
      filterMap,
      channelTypeMap,
      selected,
      createFinal,
      notList,
      joinNot,

      loading,
      statusPointColorMap,
      batchAdjustDifference,
      visibleBatchAdjust,
      visibleLoading,
      visibleImportFail,
      batchSubmit,
      upPercentage,
      failList,
      processNumbers,
      addSpace,
    }
  },
}
</script>
<template>
  <AppView>
    <template #header>
      <div class="flex-vertical" style="width: 100%">
        <SearchForm ref="form" :columns="searchConfig" :value="searchForm" :search="search" :reset="reset" />
      </div>
    </template>
    <template #main-header>
      <div class="d-flex justify-space-between align-end">
        <div>
          <v-btn v-auth="['ytAccountForm-generate-statement']" class="mr-2" color="primary" @click="createFinal">
            生成结算单
          </v-btn>
          <v-btn v-auth="['ytAccountForm-adjust-difference']" outlined class="mr-2" @click="batchAdjustDifference">
            批量调差
          </v-btn>
          <v-btn v-auth="['ytAccountForm-join-reversal']" outlined class="mr-2" @click="joinNot">
            加入未到账
          </v-btn>
          <v-btn v-auth="['ytAccountForm-reversal-list']" outlined class="mr-2" @click="notList">
            未到账列表
          </v-btn>
          <v-btn v-auth="['ytAccountForm-regenerate']" outlined class="mr-2" @click="batchGenerate">
            批量重新生成
          </v-btn>
          <v-btn v-auth="['ytAccountForm-reversal-export']" outlined @click="exportExcel">
            {{ addSpace('导出') }}
          </v-btn>
        </div>

        <div class="num">
          CID：<span>{{ processNumbers(cidForm.reportRevenue) }}</span> 美国CID：<span>{{
            processNumbers(cidForm.reportRevenueUs)
          }}</span>
          新加坡CID：<span>{{ processNumbers(cidForm.reportRevenueSg) }}</span>
        </div>
      </div>
      <div></div>
    </template>
    <SuperTable
      ref="table"
      :columns="tableColumn2"
      :data="tableData"
      :cell-style="cellStyle"
      :header-cell-style="headerCellStyle"
      :range="true"
      :loading="loading"
      @checkBoxChange="checkBoxChange"
      @selectAllEvent="selectAllEvent"
    >
      <template #channelType="{ row }">
        <span v-if="!row.channelSplitStatus">{{ filterMap('channelTypeOptions', row.channelType) }}</span>
        <span v-else> {{ filterMap('channelTypeOptions', row.channelType) }}(已拆) </span>
      </template>
      <template #channelName="{ row }">
        <div>{{ row.channelName }}</div>
        <div>
          {{ row.channelId }}
        </div>
      </template>
      <template #cms="{ row }">
        {{ filterMap('cmsOptions', row.cms) }}
      </template>
      <template #federalTax="{ row }">
        {{ row.federalTax ? `${row.federalTax}%`: '-' }}
      </template>
      <template #singaporeTax="{ row }">
        {{ row.singaporeTax ? `${row.singaporeTax}%`: '-' }}
      </template>
      <template #proportion="{ row }">
        {{ row.proportion ? `${row.proportion}%`: '-' }}
      </template>
      <template #serviceCharge="{ row }">
        {{ row.serviceCharge ? `${row.serviceCharge}%`: '-' }}
      </template>
      <template #settlementCreatedStatus="{ row }">
        <div class="sortBox">
          <div class="point" :style="{ background: statusPointColorMap[row.settlementCreatedStatus]}"></div>
          <div>{{ filterMap('statusOptions',row.settlementCreatedStatus) }}</div>
        </div>
      </template>
      <template #actions="{ row }">
        <span
          v-if="[channelTypeMap.singleOpen, channelTypeMap.compilation].includes(row.channelType) && row.settlementCreatedStatus === 0"
          v-auth="['ytAccountForm-adjust-difference']"
          class="primary--text ml-5"
          @click="adjustDifference(row)"
        >调差</span>
        <span
          v-if="[channelTypeMap.singleOpen, channelTypeMap.compilation].includes(row.channelType)"
          v-auth="['ytAccountForm-subset']"
          class="primary--text ml-5"
          @click="split(row)"
        >拆分子集</span>
        <span v-if="row.channelType === channelTypeMap.subset" v-auth="['ytAccountForm-del-subset']" class="primary--text ml-5" @click="del(row.id)">删除</span>
      </template>
    </SuperTable>

    <template #footer>
      <Pagination
        :page-size="page.pageSize"
        :page-index="page.page"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>

    <Adjust v-if="visibleAdjust" :form="revenueForm" @close="visibleAdjust = false" @refresh="init" />

    <Subset v-if="visibleSubset" :form="revenueForm.info" @close="visibleSubset = false" @refresh="init" />

    <createFinalDialog
      v-if="visibleCreateFinal"
      :form="{data: selected, month:searchForm.month,platform:'YT'}"
      @close="visibleCreateFinal = false"
      @refresh="init"
    />

    <NotListDialog v-if="visibleNotList" :form-data="searchForm" @close="visibleNotList = false" @refresh="init" />

    <BatchAdjustDialog v-if="visibleBatchAdjust" @close="visibleBatchAdjust = false" @submit="batchSubmit" />

    <ProgressLoading v-if="visibleLoading" :progress="upPercentage" @close="visibleLoading = false" />

    <ImportFailDialog v-if="visibleImportFail" :data="failList" @close="visibleImportFail = false" @closeBatch="visibleBatchAdjust = false,visibleImportFail = false" />
  </AppView>
</template>
<style scoped lang="scss">
.num {
  color: rgba($color: #000000, $alpha: 0.8);
  padding: 0 10px;
  span {
    color: #ffa80f;
    padding: 0 10px;
  }
}
span {
  cursor: pointer;
}
</style>
