<script>
import { computed, ref } from '@vue/composition-api'
import GridItem from '@/components/Grid/GridItem.vue'
import Grid from '@/components/Grid/Grid.vue'
import SearchFormItem from '@/components/SearchForm/SearchFormItem.vue'
import { VTextField, VSelect, VAutocomplete } from 'vuetify/lib/components'
import TextFieldDataPicker from '@/views/components/form-components/date-picker/TextFieldDataPicker.vue'
import DatePickers from '@/components/DatePicker.vue'
import DateMonthRange from '../ElementDateTimePicker/DateMonthRange.vue'
import DateTimeRange from '../ElementDateTimePicker/DateTimeRange.vue'

export default {
  name: 'SearchForm',
  components: {
    SearchFormItem,
    Grid,
    GridItem,
    VTextField,
    VSelect,
    TextFieldDataPicker,
    VAutocomplete,
    DatePickers,
    DateMonthRange,
    DateTimeRange,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    // 搜索配置
    columns: {
      type: Array,
      default: () => [],
    },
    // 搜索参数
    value: {
      type: Object,
      default: () => ({}),
    },
    // 初始搜索列数
    searchCol: {
      type: [Number, Object],
      default: () => ({ xs: 1, sm: 2, md: 2, lg: 3, xl: 5 }),
    },
    // 搜索
    search: {
      type: Function,
      default: () => {},
    },
    // 重置
    reset: {
      type: Function,
      default: () => {},
    },

    changeFn: {
      type: Function,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    // 获取响应式设置
    function getResponsive(item) {
      return {
        span: item?.span,
        offset: item?.offset ?? 0,
        xs: item?.xs,
        sm: item?.sm,
        md: item?.md,
        lg: item?.lg,
        xl: item?.xl,
      }
    }
    const formRef = ref()

    // 是否默认折叠搜索项
    const collapsed = ref(true)

    // 获取显示的搜索条件
    const columnsShow = computed(() => props.columns.filter(item => !item.hidden))

    // 获取响应式断点
    const gridRef = ref()
    const breakPoint = computed(() => gridRef.value?.breakPoint)
    const currentPoint = computed(() => props.searchCol[breakPoint.value] || 0)

    // 判断是否显示 展开/合并 按钮

    const showCollapse = computed(() => {
      let show = false
      props.columns.reduce((prev, current) => {
        // eslint-disable-next-line no-param-reassign
        prev
          += (current[breakPoint.value]?.span ?? current?.span ?? 1)
        + (current[breakPoint.value]?.offset ?? current?.offset ?? 0)
        if (typeof props.searchCol !== 'number') {
          if (prev > (currentPoint.value - 1)) {
            show = true
          }
        } else if (prev > props.searchCol) {
          show = true
        }

        return prev
      }, 0)

      return show
    })

    const resetForm = () => {
      formRef.value.reset()
    }
    const searchParam = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })

    const handleChange = (key, val) => {
      emit('change', { key, val })
    }

    return {
      gridRef,
      collapsed,
      getResponsive,
      showCollapse,
      searchParam,
      columnsShow,
      formRef,
      resetForm,
      handleChange,
    }
  },
}
</script>

<template>
  <div v-if="columns.length" class="search">
    <v-form ref="formRef">
      <Grid ref="gridRef" :collapsed="collapsed" :cols="searchCol" :gap="30">
        <GridItem v-for="(column, i) in columnsShow" :key="column.prop" :index="i" v-bind="getResponsive(column)">
          <SearchFormItem :label="column.label">
            <component
              :is="column.el"
              v-model.trim="searchParam[column.props]"
              :clearable="column.clearable === undefined ? true : column.clearable"
              :placeholder="column.placeholder || column.label"
              dense
              hide-details
              label=""
              outlined
              v-bind="column"
              @change="(val) => handleChange(column.props,val)"
            >
              <template v-if="column.others" #selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.label }}</span>
                </v-chip>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                >
                  (+{{ (searchParam[column.props] || []).length - 1 }} others)
                </span>
              </template>
            </component>
          </SearchFormItem>
        </GridItem>
        <GridItem suffix>
          <div class="operation">
            <v-btn class="mr12" outlined @click="reset">
              重&nbsp;置
            </v-btn>
            <v-btn color="primary" @click="search">
              查&nbsp;询
            </v-btn>
            <span v-if="showCollapse" class="ml12 primary--text" @click="collapsed = !collapsed">
              {{ !collapsed ? '收起' : '展开' }}
              <v-icon>
                {{ !collapsed ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </span>
          </div>
        </GridItem>
      </Grid>
    </v-form>
  </div>
</template>

<style lang='scss' scoped>
.search {
  width: 100%;
  padding: 20px 0;
}
.operation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
::v-deep.v-autocomplete {
  .v-select__slot {
    > * {
      cursor: pointer !important;
    }
  }
}
span{
  cursor: pointer;
}
</style>
