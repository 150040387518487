import { render, staticRenderFns } from "./imagePreview.vue?vue&type=template&id=5adb7219&scoped=true&"
import script from "./imagePreview.vue?vue&type=script&lang=js&"
export * from "./imagePreview.vue?vue&type=script&lang=js&"
import style0 from "./imagePreview.vue?vue&type=style&index=0&id=5adb7219&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5adb7219",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VDialog,VImg})
