<script>
import { replaceString } from '@/@core/utils'
import { useMessage } from '@/hooks/useMessage'
import { numberValidator } from '@/@core/utils/validation'
import { VTextField, VTextarea } from 'vuetify/lib/components'
import { onMounted, reactive, readonly, ref } from '@vue/composition-api'
import Dialog from '@/components/Dialog/index.vue'
import { adjustDifference } from '../service'

export default {
  name: 'AdjustDialog',
  components: {
    Dialog, VTextField, VTextarea,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { message } = useMessage()
    const formData = reactive({
      cmsRevenueAdjust: undefined,
      cmsRevenueAdjustRemark: undefined,
      cmsRevenueUsAdjust: undefined,
      cmsRevenueUsAdjustRemark: undefined,
      cmsRevenueSgAdjust: undefined,
      cmsRevenueSgAdjustRemark: undefined,
    })
    const formConfig = readonly([
      { element: 'VTextField', label: 'CMS导出收益（25号）调差', prop: 'cmsRevenueAdjust', placeholder: '请输入', rules: [numberValidator], counter: 20, suffix: '美元' },
      { element: 'VTextarea', label: '调差备注1', prop: 'cmsRevenueAdjustRemark', placeholder: '请输入', counter: 100 },
      { element: 'VTextField', label: 'CMS导出美国收益（25号）调差', prop: 'cmsRevenueUsAdjust', placeholder: '请输入', rules: [numberValidator], counter: 20, suffix: '美元' },
      { element: 'VTextarea', label: '调差备注2', prop: 'cmsRevenueUsAdjustRemark', placeholder: '请输入', counter: 100 },
      { element: 'VTextField', label: 'CMS导出新加坡收益（25号）调差', prop: 'cmsRevenueSgAdjust', placeholder: '请输入', rules: [numberValidator], counter: 20, suffix: '美元' },
      { element: 'VTextarea', label: '调差备注3', prop: 'cmsRevenueSgAdjustRemark', placeholder: '请输入', counter: 100 },
    ])
    Object.assign(formData, props.form.list)
    const show = ref(true)

    const close = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 300)
    }

    const submit = () => {
      replaceString(formData)
      console.log(formData)
      adjustDifference(props.form.info.id, formData).then(({ data }) => {
        if (data.status === 200) message.success('保存成功')
        emit('refresh')
        close()
      }).catch(error => message.error(error.response.data.message))
    }

    onMounted(() => {

    })

    return {
      show,
      formConfig,
      formData,
      submit,
      close,
      replaceString,
    }
  },
}
</script>
<template>
  <Dialog
    v-model="show"
    class="adjust-dialog"
    show-footer
    title="调差"
    :subtitle-text="`( ${form.info.title} )`"
    confirm-btn-text="保存"
    width="700px"
    @confirm="submit"
    @cancel="close"
  >
    <div class="remark">
      注：减少请输入负值，增加为正值；单位为美元
      <div>请输入数字、小数点和负号，长度最多20，小数点后最多2位</div>
    </div>
    <div class="list">
      <div v-for="item,index in formConfig" :key="index">
        <div>{{ item.label }}</div>
        <component
          :is="item.element"
          v-model.trim="formData[item.prop]"
          class="py-2"
          v-bind="item"
          :placeholder="item.placeholder"
          :rules="item.rules"
          :counter="item.counter"
          :suffix="item.suffix"
          hide-details
          dense
          outlined
          auto-grow
          :row-height="5"
          item-text="text"
          item-value="value"
          label=""
          inset
          @input="replaceString(formData)"
        >
        </component>
      </div>
    </div>
  </Dialog>
</template>
<style scoped lang='scss'>
.remark{
    color: #F24545;
    font-size: 12px;
}
.list{
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    margin-top: 12px;
}

.adjust-dialog{
  max-height: 600px;
  height: 500px;
}
</style>
