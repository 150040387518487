import http from '@http'
import axios from '@axios'
import config from '../../../../config'

const prefix = config.serviceUrl
/**
 * 冲销表调差
 * @param {*} params
 * @returns
 */
export function adjustDifference(id, params) {
  return http.post(`/calculateReport/adjustDifference/${id}`, params)
}

/**
 * 获取频道绑定国内频道
 * @param {*} params
 * @returns
 */
export function domesticChannels(params) {
  return http.get('/calculateReport/domesticChannels/yt', params)
}

/**
 * 暂估报表
 * @param {*} params
 * @returns
 */
export function estimateList(params) {
  return http.get('/calculateReport/estimate', params)
}

/**
 * 删除子集(暂估)
 * @param {*} id
 * @returns
 */
export function estimateDelete(id) {
  return http.get(`/calculateReport/estimate/delete/${id}`)
}

/**
 * 暂估报表导出
 * @param {*} data
 * @returns
 */
export function estimateExport(params) {
  return http.downloadGet('/calculateReport/estimate/export', params)
}

/**
 * 冲销报表
 * @param {*} list
 * @returns
 */
export function reversalList(params) {
  return http.get('/calculateReport/reversal', params)
}

/**
 * 删除子集(冲销)
 * @param {*} id
 * @returns
 */
export function reversalDelete(id) {
  return http.get(`/calculateReport/reversal/delete/${id}`)
}

/**
 * 冲销报表导出
 * @param {*} data
 * @returns
 */
export function reversalExport(params) {
  return http.downloadGet('/calculateReport/reversal/export', params)
}

/**
 * 冲销报表批量重新生成
 * @param {*}
 * @returns
 */
export function reversalRegenerate(params) {
  return http.post('/calculateReport/reversal/regenerate', params)
}

/**
 * 已拆分收益
 * @param {*}
 * @returns
 */
export function revenueSplit(params) {
  return http.get('/calculateReport/split/revenue', params)
}

/**
 * 拆分子集(暂估)
 * @param {*}
 * @returns
 */
export function subsetSplitEstimate(id, params) {
  return http.post(`/calculateReport/split/subset/estimate/${id}`, params)
}

/**
 * 拆分子集(冲销)
 * @param {*}
 * @returns
 */
export function subsetSplitReversal(id, params) {
  return http.post(`/calculateReport/split/subset/reversal/${id}`, params)
}

/**
 * 冲销报表(CID统计)
 * @param {*}
 * @returns
 */
export function reportCid(params) {
  return http.get('/calculateReport/cid', params)
}

/**
 * 生成结算单（YT）
 * @param {*}
 * @returns
 */
export function generateYt(params) {
  return http.post('/settlement/generate/YT', params)
}

/**
 * 生成结算单（FB）
 * @param {*}
 * @returns
 */
export function generateFb(params) {
  return http.post('/settlement/generate/FB', params)
}

/**
 * 批量加入未到账
 * @param {*}
 * @returns
 */
export function joinUnreceived(params) {
  return http.post('/calculateReport/unreceived', params)
}

/**
 * 未到账还原
 * @param {*}
 * @returns
 */
export function unreceivedRestore(id) {
  return http.post(`/calculateReport/unreceived/${id}`)
}

/**
 * YT冲销批量调差-导入
 * @param {*} file
 * @returns
 */
export function reversalImport(data, key, dateTime, onUploadProgress) {
  return axios({
    url: `${prefix}/calculateReport/reversalImport?key=${key}&dateTime=${dateTime}`,
    method: 'post',
    onUploadProgress,
    data,
  })
}
/**
 * YT冲销批量调差导入失败
 * @param {*} data
 * @returns
 */
export function reversalExportFila(params) {
  return http.downloadPost('/calculateReport/export', params)
}
