<script>
import {
  defineComponent, computed, ref, onMounted, watch,
} from '@vue/composition-api'
import { thumbnailSuffix } from '@core/utils/options'

export default defineComponent({
  props: {
    // 文件名
    name: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const showImage = ref(false)
    const suffix = ref('')
    const prefix = ref('')

    // 获取文件名和后缀
    const getFileNameAndPrefix = () => {
      if (props.name) {
        const fileNameStr = props.name.split('.')

        suffix.value = fileNameStr.pop()
        prefix.value = fileNameStr.join('')
      }
    }

    const isImge = computed(() => thumbnailSuffix.includes(`.${suffix.value}`))

    const handlePreview = () => {
      showImage.value = true
    }

    watch(() => props.name, getFileNameAndPrefix)

    onMounted(() => {
      getFileNameAndPrefix()
    })

    return {
      isImge,
      showImage,
      suffix,
      prefix,
      handlePreview,
    }
  },
})
</script>

<template>
  <div
    v-if="name"
    class="link"
  >
    <i
      v-if="icon"
      class="iconfont"
      :class="icon"
    ></i>
    <a
      v-if="!isImge"
      :href="src"
      target="_blank"
      :title="name"
    ><span class="link-name">{{ prefix }}</span><span class="link-suffix">.{{ suffix }}</span></a>
    <a
      v-else
      @click="handlePreview"
    ><span class="link-name">{{ prefix }}</span><span class="link-suffix">.{{ suffix }}</span></a>

    <v-dialog
      v-model="showImage"
      width="500"
      height="500"
    >
      <div class="imgBox">
        <img
          :src="src"
        />
      </div>
    </v-dialog>
  </div>
  <div v-else>
    -
  </div>
</template>
<style lang="scss" scoped>
.link{
  color: rgba(0, 109, 255, 1);
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  a{
    color: rgba(0, 0, 0, 0.88)!important;
    text-decoration: none;
    display: flex;
    width: 100%;
    overflow: hidden;
    .link-name{
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;
      overflow: hidden;
    }
    .link-suffix{
      white-space: nowrap;
    }
  }

  .iconfont{
    color: rgba(0, 0, 0, 0.88)!important;
    font-size: 20px;
    margin-right: 4px;
    padding-top: 2px;
    width: 20px;
  }
}
.link:hover{
  a{
    color: rgba(0, 109, 255, 1)!important;
    text-decoration: none;
  }
  .iconfont{
    color: rgba(0, 109, 255, 1)!important;
  }

}
.imgBox{
    width: 500px;
    height: 500px;
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -250px;
    margin-top: -250px;
    z-index: 22;
    border-radius: 6px;
    overflow: hidden;
    img{
      max-width: 500px;
      max-height: 500px;
    }
  }

</style>
