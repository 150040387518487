<script>
import { computed } from '@vue/composition-api'
import { isNull } from '@core/utils/is'

export default {
  name: 'DateTimeRange',
  components: {},
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: [String, Array],
      default: () => null,
    },
    rangeSeparator: {
      type: String,
      default: '-',
    },
    startPlaceholder: {
      type: String,
      default: '开始日期',
    },
    endPlaceholder: {
      type: String,
      default: '结束日期',
    },
  },
  setup(props, { emit }) {
    const dateValue = computed({
      get() {
        return props.value
      },
      set(val) {
        console.log(val)
        if (isNull(val)) return emit('input', [])

        return emit('input', val)
      },
    })

    return { dateValue }
  },
}
</script>
<template>
  <div>
    <el-date-picker
      v-model="dateValue"
      style="width: 100%;"
      type="datetimerange"
      :range-separator="rangeSeparator"
      :start-placeholder="startPlaceholder"
      :end-placeholder="endPlaceholder"
      value-format="yyyy-MM-dd HH:mm:ss"
      popper-class="datePickerClass"
    >
    </el-date-picker>
  </div>
</template>
<style scoped lang='scss'>
::v-deep .el-time-panel{
  // left: -30px !important;
  width: 150px !important;
}
</style>
