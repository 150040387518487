<script>
import { ref, computed } from '@vue/composition-api'
import Dialog from '@/components/Dialog/index.vue'

export default {
  name: 'ProgressLoading',
  components: { Dialog },
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '正在提交',
    },
  },
  setup(props, { emit }) {
    const show = ref(true)

    const value = computed(() => props.progress)

    const close = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 300)
    }

    const submit = () => {

    }

    return {
      show,
      close,
      submit,
      value,

    }
  },
}
</script>
<template>
  <Dialog
    v-model="show"
    :title="title"
    width="500px"
    @cancel="close"
  >
    <div class="progress">
      <v-progress-linear :value="value" height="10" background-color="#DFE4EB" rounded></v-progress-linear>
      <div class="num">
        {{ value }}%
      </div>
    </div>
    <div class="text">
      数据校验中…
    </div>
  </Dialog>
</template>
<style scoped lang='scss'>
::v-deep .v-progress-linear__determinate{
  background: linear-gradient( 90deg, #C48EFF 0%, #9155FD 100%);
}
.progress{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .num{
    font-size: 14px;
    color: rgba(0,0,0,0.5);
    font-weight: 500;
    margin-left: 15px;
  }
}
.text{
  font-size: 14px;
  color: rgba(0,0,0,0.85);
  margin-top: 10px;
}
</style>
