<script>
export default {
  name: 'SearchFormItem',
  props: {
    label: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <div class="form-item">
    <div class="form-item-label fs15" style="flex-shrink: 1">
      {{ label }}
    </div>
    <div style="width: 100%; cursor: pointer">
      <slot />
    </div>
  </div>
</template>

<style lang='scss' scoped>
.form-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;

  &-label {
    font-weight: 400;
    width: 84px;
    text-align: right;
     white-space: wrap; //不换行
      overflow: hidden; //隐藏溢出
      text-overflow: ellipsis; //省略号
      display: -webkit-box; //-webkit-box是兼容IOS和老版本Android浏览器的方式
      -webkit-line-clamp: 2; //限制行数
      -webkit-box-orient: vertical; //垂直方向排列
  }
}
.v-input {
  ::v-deep .v-input__control {
    .v-input__slot {
      height: 32px;
      margin: 0;
    }
    .v-text-field__details {
      display: none;
    }
  }
}
</style>
