<script>
import { asynchronousReturn, replaceString } from '@/@core/utils'
import { useMessage } from '@/hooks/useMessage'
import { required } from '@/@core/utils/validation'
import { VTextField } from 'vuetify/lib/components'
import SuperTable from '@/components/SuperTable.vue'
import { ref, onMounted } from '@vue/composition-api'
import Dialog from '@/components/Dialog/index.vue'
import { revenueSplit, domesticChannels, subsetSplitEstimate, subsetSplitReversal } from '../service'

export default {
  name: 'SubsetDialog',
  components: {
    Dialog, SuperTable, VTextField,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const formRef = ref()
    const { message } = useMessage()
    const options = ref([])
    const tableData = ref([
      { name1: 'CMS导出收益', earning1: '-', name2: '已拆分收益', earning2: '-' },
      { name1: 'CMS导出美国收益', earning1: '-', name2: '已拆分美国收益', earning2: '-' },
      { name1: 'CMS导出新加坡收益', earning1: '-', name2: '已拆分新加坡收益', earning2: '-' },
    ])
    const tableColumns = [
      { value: 'name1', text: '名称' },
      { value: 'earning1', text: '汇率', align: 'right' },
      { value: 'name2', text: '收益' },
      { value: 'earning2', text: '金额', align: 'right' },
    ]
    const subsetData = ref([
      { revenue: '', sgRevenue: '', subsetName: '', usRevenue: '' },
    ])
    const show = ref(true)

    const init = () => {
      revenueSplit(props.form).then(({ data }) => {
        tableData.value[0].earning1 = data.data.cmsRevenue
        tableData.value[0].earning2 = data.data.cmsRevenueSplit
        tableData.value[1].earning1 = data.data.cmsRevenueUs
        tableData.value[1].earning2 = data.data.cmsRevenueUsSplit
        tableData.value[2].earning1 = data.data.cmsRevenueSg
        tableData.value[2].earning2 = data.data.cmsRevenueSgSplit
      }).catch(error => message.error(error.response.data.message))
    }

    const addSubset = () => {
      subsetData.value.push({ revenue: '', sgRevenue: '', subsetName: '', usRevenue: '' })
    }

    const removeSubset = index => {
      subsetData.value.splice(index, 1)
    }

    const rowClassName = ({ rowIndex }) => {
      switch (rowIndex) {
        case 0:

          return 'row-background1'
        case 1:

          return 'row-background2'
        case 2:

          return 'row-background3'

        default:
          break
      }
    }

    const close = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 300)
    }

    const submit = () => {
      replaceString(subsetData.value)
      asynchronousReturn(formRef.value.validate).then(() => {
        subsetData.value = subsetData.value.map(item => {
          const res = options.value.find(ele => ele.title === item.subsetName)
          console.log(res)

          return {
            ...item,
            subsetId: res.channelId,
          }
        })
        replaceString(subsetData.value)
        if (props.form.type === 'estimate') {
          subsetSplitEstimate(props.form.id, { subsetData: subsetData.value }).then(({ data }) => {
            console.log(data)
            if (data.status === 200) {
              message.success('拆分成功')
              emit('refresh')
              close()
            }
          }).catch(error => message.error(error.response.data.message))
        }
        if (props.form.type === 'reversal') {
          subsetSplitReversal(props.form.id, { subsetData: subsetData.value }).then(({ data }) => {
            if (data.status === 200) {
              message.success('拆分成功')
              emit('refresh')
              close()
            }
          }).catch(error => message.error(error.response.data.message))
        }
      }).catch(error => error)
    }

    const domesticChannelsOptions = () => {
      domesticChannels({ channelId: props.form.channelId }).then(({ data }) => {
        options.value = data.data
      }).catch(error => message.error(error.response.data.message))
    }
    onMounted(() => {
      domesticChannelsOptions()
      init()
    })

    return {
      submit,
      close,
      show,
      tableColumns,
      tableData,
      subsetData,
      rowClassName,
      addSubset,
      removeSubset,
      required,
      options,
      formRef,
    }
  },
}
</script>
<template>
  <Dialog
    v-model="show"
    class="dialog"
    show-footer
    title="拆分子集"
    confirm-btn-text="保存"
    width="800px"
    @confirm="submit"
    @cancel="close"
  >
    <div class="tableTitle">
      <div class="remark">
        {{ form.title }} <span>注：以下收益为调差后的收益</span>
      </div>
      <SuperTable
        class="my-3"
        :data="tableData"
        :columns="tableColumns"
        :show-header="false"
        :row-class-name="rowClassName"
        height="180px"
      />
    </div>
    <div class="table">
      <v-form ref="formRef">
        <div v-for="item,index in subsetData" :key="index">
          <div class="py-3 font-weight-bold d-flex align-center">
            <img v-if="subsetData.length > 1" src="@/assets/images/pages/remove@2x.png" alt="" style="height: 20px; object-fit: fill;" class="mr-1 cup" @click="removeSubset(index)"> 子集{{ index + 1 }}
          </div>
          <div class="list">
            <div class="item">
              <div class="label">
                子集名称
              </div>
              <v-autocomplete
                v-model="item.subsetName"
                placeholder="请输入"
                :rules="[required]"
                :items="options"
                hide-details
                dense
                clearable
                outlined
                item-text="title"
                item-value="title"
                inset
              ></v-autocomplete>
            </div>
            <div class="item">
              <label class="label">
                频道收益
              </label>
              <v-text-field
                v-model="item.revenue"
                placeholder="请输入"
                :rules="[required]"
                hide-details
                dense
                clearable
                outlined
                item-text="text"
                item-value="value"
                inset
              ></v-text-field>
            </div>
            <div class="item">
              <label class="label">
                美国收益
              </label>
              <v-text-field
                v-model="item.usRevenue"
                placeholder="请输入"
                :rules="[required]"
                hide-details
                dense
                clearable
                outlined
                item-text="text"
                item-value="value"
                inset
              ></v-text-field>
            </div>
            <div class="item">
              <label class="label">
                新加坡收益
              </label>
              <v-text-field
                v-model="item.sgRevenue"
                placeholder="请输入"
                :rules="[required]"
                hide-details
                dense
                clearable
                outlined
                item-text="text"
                item-value="value"
                inset
              ></v-text-field>
            </div>
          </div>

          <v-divider></v-divider>
        </div>
      </v-form>
    </div>
    <div class="d-flex align-center py-3 cup" @click="addSubset">
      <img src="@/assets/images/pages/add@2x.png" alt="" style="height: 20px; object-fit: fill;" class="mr-1" />
      新增子集
    </div>
  </Dialog>
</template>
<style scoped lang='scss'>
.cup{
    cursor: pointer;
}
.tableTitle{
    padding: 20px;
    border: 1px dashed #E8E9EC;
    background: #F6F7F8;
    border-radius: 4px;
    &::v-deep .vxe-table--render-default.border--default .vxe-body--column{
        border-left: 2px solid #fff;
        border-top: 2px solid #fff;
        &:last-child{
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
        }
    }
.remark{
    font-weight: 600;
    font-size: 20px;
    color: #000;
   span{
    font-weight: normal;
    color: #F24545;
    font-size: 14px;
    margin-left: 5px;
   }
}
}

.table{
    overflow-y: auto;
    max-height: 400px;
    padding: 0 5px;
}
.list{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 16px;

    .item{
        display: flex;
        align-items: center;
    }
    .label{
        width: 100px;
        &::before{
            content: '*';
            color: #F24545;
        }
    }
}
::v-deep .vxe-body--row.row-background1{
    background: rgba(0,176,155,0.06);
}
::v-deep .vxe-body--row.row-background2{
    background: rgba(255,168,15,0.06);
}
::v-deep .vxe-body--row.row-background3{
    background:rgba(145,85,253,0.06);
}

</style>
