<script>
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  props: {
    src: {
      type: String,
      default: '',
    },
  },
  setup() {
    const showImage = ref(false)
    const handlePreview = () => {
      showImage.value = true
    }

    return { showImage, handlePreview }
  },
})
</script>

<template>
  <div
    class="img-prevew"
  >
    <v-img
      :src="src"
      width="100%"
      height="100%"
      @click="handlePreview"
    ></v-img>
    <v-dialog
      v-model="showImage"
      width="500"
      height="500"
    >
      <div class="imgBox">
        <img
          :src="src"
        />
      </div>
    </v-dialog>
  </div>
</template>
<style lang="scss" scoped>
.img-prevew{
  width: 100%;
  height: 100%;
  .v-image{
    border-radius: 6px;
    overflow: hidden;
  }
}
.imgBox{
    width: 500px;
    height: 500px;
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -250px;
    margin-top: -250px;
    z-index: 22;
    border-radius: 6px;
    overflow: hidden;
    img{
      max-width: 500px;
      max-height: 500px;
    }
  }
</style>
