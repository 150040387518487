<template>
  <div class="pagination">
    <div class="fs14">
      总共 {{ total }} 条
    </div>
    <div style="height: 40px">
      <v-pagination
        v-if="showPagination"
        v-model="currentPage"
        total-visible="8"
        :length="pageLength"
      />
    </div>
    <div style="width: 108px">
      <v-select
        v-model="Size"
        :items="pageSizes"
        dense
        outlined
        @change="selectChange"
      >
        <template #selection="{ item }">
          <span>{{ item }}条/页</span>
        </template>
      </v-select>
    </div>
    <div
      v-if="showJump"
      class="pagination_jump"
    >
      <span class="fs14 px5" style="flex-shrink: 0;">跳至</span>
      <v-text-field
        v-model="pageInput"
        dense
        outlined
        @keyup="pageInput=pageInput.replace(/^(0+)|[^\d]+/g,'')"
        @keydown.enter="pageJump"
      />
      <span style="padding-left: 5px">页</span>
    </div>
  </div>
</template>

<script>

// pageIndex: 1, pageSize: 10, total: 0,
export default {
  name: 'Pagination',
  props: {
    // 当前页
    pageIndex: {
      type: Number,
      default: 1,
    },
    // 每页显示条数
    pageSize: {
      type: Number,
      default: 10,
    },
    // 总条数
    total: {
      type: Number,
      default: 0,
    },
    // 分页下拉显示
    pageSizes: {
      type: Array,
      default: () => [5, 20, 50, 100],
    },
    showJump: {
      type: [Boolean, String],
      default: true,
    },
  },
  data() {
    return {
      pageInput: 1,
      showPagination: false,
      time: null,
    }
  },
  computed: {
    // 分页比例
    Size: {
      get() {
        return this.pageSize
      },
      set(val) {
        this.$emit('size-change', val)
      },
    },
    // 长度
    pageLength() {
      return (Math.ceil(this.total / this.pageSize)) || 1
    },
    // 页
    currentPage: {
      get() {
        return this.pageIndex
      },
      set(val) {
        this.$emit('current-change', val)
      },
    },
  },
  watch: {
    pageIndex: {
      handler(val) {
        this.pageInput = val
      },
      deep: true,
    },
    total: {
      handler() {
        this.showPagination = false
        this.$nextTick(() => {
          const time = setTimeout(() => {
            this.showPagination = true
            clearTimeout(time)
          }, 100)
        })
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.showPagination = false
    this.$nextTick(() => {
      const time = setTimeout(() => {
        this.showPagination = true
        clearTimeout(time)
      }, 100)
    })
  },
  methods: {
    selectChange() {
      this.showPagination = false
      this.$nextTick(() => {
        const time = setTimeout(() => {
          this.showPagination = true
          clearTimeout(time)
        }, 100)
      })
    },
    pageJump() {
      const numLength = Number(this.pageInput)
      if (numLength > this.pageLength) {
        this.pageInput = this.pageLength
        this.$emit('current-change', this.pageLength)

        return
      }

      this.$emit('current-change', numLength)
    },
  },
}
</script>

<style scoped lang='scss'>
.pagination {
  padding: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &_jump {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 120px;
  }
}

.v-input {
  ::v-deep .v-input__control {
    .v-input__slot {
      min-height: 35px !important;
      height: 35px;
      margin: 0;
      .v-select__selections{
        input{
          display: none;
        }
      }

      .v-input__append-inner {
        margin-top: 5px;
      }
    }
    .v-text-field__details {
      display: none;
    }
  }
}
</style>
