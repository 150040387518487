import mapEnum from './mapEnum'

export const tableColumn1 = [
  { text: '频道名称/ID', value: 'channelName', width: 250 },
  { text: '月份', value: 'month', width: 100 },
  { text: '收款系统', value: 'cms', width: 120 },
  { text: '运营部门', value: 'operationDept', width: 120, enum: mapEnum.operationOptions },
  { text: '运营类型', value: 'operationType', width: 120, enum: mapEnum.operationType },
  { text: '频道类型', value: 'channelType', width: 120, enum: mapEnum.channelTypeOptions },
  { text: '子集名称', value: 'subsetName', width: 120 },
  { text: 'Rpt-CMS收益差异', value: 'rptCmsDifference', width: 150, align: 'right' },
  { text: 'Rpt收益', value: 'rptRevenue', width: 120, align: 'right' },
  { text: 'Rpt美国收益', value: 'rptRevenueUs', width: 150, align: 'right' },
  { text: 'Rpt新加坡收益', value: 'rptRevenueSg', width: 160, align: 'right' },
  { text: 'Rpt联邦税', value: 'federalTaxRptAmount', width: 120, align: 'right' },
  { text: 'Rpt新加坡税额', value: 'singaporeTaxRptAmount', width: 120, align: 'right' },
  { text: 'CMS导出收益', value: 'cmsRevenue', width: 120, align: 'right' },
  { text: 'CMS导出美国收益', value: 'cmsRevenueUs', width: 150, align: 'right' },
  { text: 'CMS导出新加坡收益', value: 'cmsRevenueSg', width: 160, align: 'right' },
  { text: '联邦税额', value: 'federalTaxAmount', width: 120, align: 'right' },
  { text: '新加坡税额', value: 'singaporeTaxAmount', width: 120, align: 'right' },
  { text: '美国联邦税率', value: 'federalTax', width: 120, align: 'right' },
  { text: '新加坡税率', value: 'singaporeTax', width: 120, align: 'right' },
  { text: '可分配收益', value: 'distributableIncome', width: 120, align: 'right' },
  { text: '客户分成比例', value: 'proportion', width: 120, align: 'right' },
  { text: '客户应发收益 ($)', value: 'expectedIncome', width: 150, align: 'right' },
  { text: '手续费率', value: 'serviceCharge', width: 120, align: 'right' },
  { text: '付款手续费 ($)', value: 'serviceChargeDollar', width: 150, align: 'right' },
  { text: '客户实发收益 ($)', value: 'actualIncomeDollar', width: 150, align: 'right' },
  { text: '汇率', value: 'rate', width: 100, align: 'right' },
  { text: '客户实发收益 (￥)', value: 'actualIncomeRmb', width: 150, align: 'right' },
  { text: '收款人', value: 'bankAccountName', width: 150 },
  { text: '证件号码', value: 'idCard', width: 200 },
  { text: '开户行', value: 'openingBank', width: 150 },
  { text: '收款账号', value: 'bankAccount', width: 200 },
  { text: '操作', value: 'actions', fixed: 'right', width: 200 },
]

export const tableColumn2 = [
  { text: '', value: 'checkbox', width: 50, type: 'checkbox' },
  { text: '频道名称/ID', value: 'channelName', width: 250 },
  { text: '月份', value: 'month', width: 100 },
  { text: '收款系统', value: 'cms', width: 120 },
  { text: '运营部门', value: 'operationDept', width: 120, enum: mapEnum.operationOptions },
  { text: '运营类型', value: 'operationType', width: 120, enum: mapEnum.operationType },
  { text: '频道类型', value: 'channelType', width: 120, enum: mapEnum.channelTypeOptions },
  { text: '子集名称', value: 'subsetName', width: 120 },
  { text: '报告-CMS收益差异', value: 'reportCmsDifference', width: 150, align: 'right' },
  { text: '报告收益', value: 'reportRevenue', width: 120, align: 'right' },
  { text: '报告美国收益', value: 'reportRevenueUs', width: 150, align: 'right' },
  { text: '报告新加坡收益', value: 'reportRevenueSg', width: 150, align: 'right' },
  { text: '报告联邦税额', value: 'reportFederalTaxAmount', width: 120, align: 'right' },
  { text: '报告新加坡税额', value: 'reportSingaporeTaxAmount', width: 120, align: 'right' },
  { text: 'CMS导出收益 (25号)', value: 'cmsRevenue', width: 160, align: 'right' },
  { text: '调差1', value: 'cmsRevenueAdjust', width: 120, align: 'right' },
  { text: '调差备注1', value: 'cmsRevenueAdjustRemark', width: 120 },
  { text: 'CMS导出美国收益 (25号)', value: 'cmsRevenueUs', width: 200, align: 'right' },
  { text: '调差2', value: 'cmsRevenueUsAdjust', width: 120, align: 'right' },
  { text: '调差备注2', value: 'cmsRevenueUsAdjustRemark', width: 120 },
  { text: 'CMS导出新加坡收益 (25号)', value: 'cmsRevenueSg', width: 210, align: 'right' },
  { text: '调差3', value: 'cmsRevenueSgAdjust', width: 120, align: 'right' },
  { text: '调差备注3', value: 'cmsRevenueSgAdjustRemark', width: 120 },
  { text: 'CMS导出联邦税额 (25号)', value: 'cmsFederalTaxAmount', width: 200, align: 'right' },
  { text: 'CMS导出新加坡税额 (25号)', value: 'cmsSingaporeTaxAmount', width: 200, align: 'right' },
  { text: '美国联邦税率', value: 'federalTax', width: 120, align: 'right' },
  { text: '新加坡税率', value: 'singaporeTax', width: 120, align: 'right' },
  { text: '可分配收益', value: 'distributableIncome', width: 120, align: 'right' },
  { text: '客户分成比例', value: 'proportion', width: 120, align: 'right' },
  { text: '客户应发收益 ($)', value: 'expectedIncome', width: 150, align: 'right' },
  { text: '手续费率', value: 'serviceCharge', width: 120, align: 'right' },
  { text: '付款手续费 ($)', value: 'serviceChargeDollar', width: 120, align: 'right' },
  { text: '客户实发收益 ($)', value: 'actualIncomeDollar', width: 150, align: 'right' },
  { text: '汇率', value: 'rate', width: 100, align: 'right' },
  { text: '客户实发收益 (￥)', value: 'actualIncomeRmb', width: 150, align: 'right' },
  { text: '收款人', value: 'bankAccountName', width: 120 },
  { text: '证件号码', value: 'idCard', width: 200 },
  { text: '开户行', value: 'openingBank', width: 150 },
  { text: '收款账号', value: 'bankAccount', width: 200 },
  { text: '结算单生成状态', value: 'settlementCreatedStatus', width: 200, enum: mapEnum.statusOptions },
  { text: '操作', value: 'actions', fixed: 'right', width: 200 },
]

export const notColumns = [
  { text: '频道名称/ID', value: 'channelName', width: 250 },
  { text: '所属月份', value: 'month', width: 120 },
  { text: '收款系统', value: 'cms', width: 120 },
  { text: '运营部门', value: 'operationDept', width: 120, enum: mapEnum.operationOptions },
  { text: '运营类型', value: 'operationType', width: 120, enum: mapEnum.operationType },
  { text: '频道类型', value: 'channelType', width: 120, enum: mapEnum.channelTypeOptions },
  { text: '子集名称', value: 'subsetName', width: 120 },
  { text: '报告-CMS收益差异', value: 'reportCmsDifference', width: 150, align: 'right' },
  { text: '报告收益', value: 'reportRevenue', width: 120, align: 'right' },
  { text: '报告美国收益', value: 'reportRevenueUs', width: 150, align: 'right' },
  { text: '报告新加坡收益', value: 'reportRevenueSg', width: 150, align: 'right' },
  { text: '报告联邦税额', value: 'reportFederalTaxAmount', width: 120, align: 'right' },
  { text: '报告新加坡税额', value: 'reportSingaporeTaxAmount', width: 120, align: 'right' },
  { text: 'CMS导出收益 (25号)', value: 'cmsRevenue', width: 160, align: 'right' },
  { text: '调差1', value: 'cmsRevenueAdjust', width: 120, align: 'right' },
  { text: '调差备注1', value: 'cmsRevenueAdjustRemark', width: 120 },
  { text: 'CMS导出美国收益 (25号)', value: 'cmsRevenueUs', width: 200, align: 'right' },
  { text: '调差2', value: 'cmsRevenueUsAdjust', width: 120, align: 'right' },
  { text: '调差备注2', value: 'cmsRevenueUsAdjustRemark', width: 120 },
  { text: 'CMS导出新加坡收益 (25号)', value: 'cmsRevenueSg', width: 210, align: 'right' },
  { text: '调差3', value: 'cmsRevenueSgAdjust', width: 120, align: 'right' },
  { text: '调差备注3', value: 'cmsRevenueSgAdjustRemark', width: 120 },
  { text: 'CMS导出联邦税额 (25号)', value: 'cmsFederalTaxAmount', width: 200, align: 'right' },
  { text: 'CMS导出新加坡税额 (25号)', value: 'cmsSingaporeTaxAmount', width: 200, align: 'right' },
  { text: '美国联邦税率', value: 'federalTax', width: 120, align: 'right' },
  { text: '新加坡税率', value: 'singaporeTax', width: 120, align: 'right' },
  { text: '可分配收益', value: 'distributableIncome', width: 120, align: 'right' },
  { text: '客户分成比例', value: 'proportion', width: 120, align: 'right' },
  { text: '客户应发收益 ($)', value: 'expectedIncome', width: 150, align: 'right' },
  { text: '手续费率', value: 'serviceCharge', width: 120, align: 'right' },
  { text: '付款手续费 ($)', value: 'serviceChargeDollar', width: 120, align: 'right' },
  { text: '客户实发收益 ($)', value: 'actualIncomeDollar', width: 150, align: 'right' },
  { text: '汇率', value: 'rate', width: 100, align: 'right' },
  { text: '客户实发收益 (￥)', value: 'actualIncomeRmb', width: 150, align: 'right' },
  { text: '收款人', value: 'bankAccountName', width: 120 },
  { text: '证件号码', value: 'idCard', width: 200 },
  { text: '开户行', value: 'openingBank', width: 150 },
  { text: '收款账号', value: 'bankAccount', width: 200 },
  { text: '结算单生成状态', value: 'settlementCreatedStatus', width: 200, enum: mapEnum.statusOptions },
  { text: '操作', value: 'actions', fixed: 'right', width: 120 },
]

export const failHeader = [
  { text: '行数', value: 'rowNum', width: 80, align: 'center' },
  { text: '原因', value: 'reasonForFailure', align: 'center' },
]
