<script>
export default {
  name: 'AppView',
  props: {
    fixed: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      mainHeight: 0,
    }
  },
  mounted() {
    const main = this.$refs.main
    const { height } = main.getBoundingClientRect()
    this.mainHeight = height - 45
  }
}
</script>
<template>
  <v-card
    style="height: 100%"
    elevation='0'
    class='app-card'
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div
      class="app-view"
      :class="{ 'app-view--fixed': fixed }"
    >
      <div
        v-if="$slots.header"
        class="app-view__header"
      >
        <slot name="header" />
      </div>
      <div class="PartingLine">
        <v-divider v-if="$slots.header" />
      </div>
      <div class="app-view__main">
        <div
          v-if="$slots['main-header']"
          class="app-view__main-header"
        >
          <slot name="main-header" />
        </div>
        <div ref='main' class="app-view__main-content" :style="{ height: mainHeight + 'px' }">
          <slot :height='mainHeight' />
        </div>
        <div v-if="$slots['main-footer']">
          <slot name="main-footer" />
        </div>
      </div>
      <div
        v-if="$slots.footer"
        class="app-view__footer"
      >
        <slot name="footer" />
      </div>
    </div>
  </v-card>
</template>

<style scoped lang='scss'>
.app-view {
  border-radius: 4px;
  &--fixed {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }

  &__main,
  &__footer{
    padding: 0.2rem 0;
  }

  &__header {
    // padding: 20px 0 0;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__main {
    display: flex;
    flex: auto;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-x: auto;
    // padding-left: 20px;
    // padding-right: 20px;

    &-header {
      margin-bottom: 0.5rem;
    }

    &-content {
      flex: auto;
    }

    &-footer {
      // margin-top: 0.5rem;
    }
  }

  &__footer {
    //margin-top: 0.5rem;
  }
}

.PartingLine {
  padding-bottom: .5rem;
}
.app-card {
  box-shadow: none;
}
</style>
