<script>
export default {
  name: 'TabPanePro',
  props: {
    label: String,
    name: String,
    disabled: {
      // 是否禁用这一项
      type: Boolean,
      default: false, // 默认不禁用
    },
  },
  computed: {
    // 控制根据高亮的tab显示对应标签页内容
    isActiveToShowContent() {
      const activeName = this.$parent.value // 比如当前高亮的是 sunwukong
      const currentName = this.name // this.name的值有很多个，有：sunwukong、zhubajie、shaheshang...

      return activeName === currentName
    },
  },
}
</script>

<template>
  <transition name="slide-fade">
    <div
      v-show="isActiveToShowContent"
      class="tab-pane-pro"
    >
      <slot />
    </div>
  </transition>
</template>

<style lang='scss' scoped>
.tab-pane-pro {
  padding: 0px;
  height: 100%;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all 0s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(100%);
  opacity: 0;
}
</style>
