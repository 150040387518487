import { operationOptions, cmsOptions, operationType, channelTypeOptions } from '@/@core/utils/options'

const mapEnum = {
  operationOptions,
  operationType,
  cmsOptions,
  channelTypeOptions,
  statusOptions: [
    { label: '全部', value: '' },
    { label: '未生成', value: 0 },
    { label: '已生成', value: 1 },
    { label: '无需生成', value: 2 },
  ],
}

export default mapEnum

export const channelTypeMap = {
  singleOpen: 0, // 单开
  subset: 1, // 子集
  compilation: 2, // 合集
}
