<script>
import moment from 'moment'
import { ref, reactive, onMounted } from '@vue/composition-api'
import AppView from '@/components/AppView.vue'
import TabPanePro from '@/components/tabs/TabPanePro.vue'
import TabsPro from '@/components/tabs/TabsPro.vue'
import RunCell from './components/RunCell.vue'
import Provisional from './components/Provisional.vue'

export default {
  name: 'YTAccountFormList',
  components: { AppView, TabPanePro, TabsPro, Provisional, RunCell },
  props: {},
  setup() {
    const activeName = ref('zg')
    const componentRef = ref()
    const tabList = [
      { label: '暂估报表', value: 'zg', element: 'Provisional' },
      { label: '冲销报表', value: 'cx', element: 'RunCell' },
    ]

    const searchForm = reactive({
      month: [moment().subtract(1, 'month').format('yyyy-MM'), moment().subtract(1, 'month').format('yyyy-MM')],
      title: undefined,
      channelId: undefined,
      cms: undefined,
      channelType: [],
      subsetName: undefined,
      settlementCreatedStatus: '', // todo
    })

    const tabClick = val => {
      activeName.value = val.name
      if (val.name === 'cx') {
        searchForm.channelType = []
      } else {
        searchForm.channelType = undefined
      }
      const index = tabList.findIndex(item => item.value === val.name)
      componentRef.value[index].search()
    }

    onMounted(() => {})

    const newTabList = ref([
      { label: '暂估报表', value: 'zg', element: 'Provisional' },
      { label: '冲销报表', value: 'cx', element: 'RunCell' },
    ])

    return {
      activeName,
      componentRef,
      newTabList,
      tabClick,
      searchForm,
      tabList,
    }
  },
}
</script>
<template>
  <v-card class="app-list">
    <tabs-pro v-model="activeName" style="height: 100%" @tabClick="tabClick">
      <tab-pane-pro v-for="item in newTabList" :key="item.value" :label="`${item.label}`" :name="item.value">
        <component :is="item.element" ref="componentRef" :key="item.value" :form-data="searchForm" :tab="item.value" :active-name="activeName">
        </component>
      </tab-pane-pro>
    </tabs-pro>
  </v-card>
</template>
<style scoped lang="scss">
::v-deep .tab-content-item-box {
  height: calc(100% - 50px);
}
</style>
