<script>
import Pagination from '@/components/Pagination.vue'
import { onMounted, ref, reactive, computed } from '@vue/composition-api'
import SuperTable from '@/components/SuperTable.vue'
import { formatNumber } from '@/@core/utils'
import { useMessage } from '@/hooks/useMessage'
import AppView from '@/components/AppView.vue'
import { unreceivedRestore, reversalList } from '../service'
import { notColumns } from '../tabCols'
import mapEnum, { channelTypeMap } from '../mapEnum'

export default {
  name: 'NotListDialog',
  components: { AppView, SuperTable, Pagination },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const show = ref(true)

    const times = computed(() => ({
      monthStart: props.formData.month[0],
      monthEnd: props.formData.month[1],
    }))

    const page = reactive({
      page: 1,
      pageSize: 20,
      total: 0,
    })
    const { message } = useMessage()
    const tableData = ref([])
    const init = () => {
      reversalList({ ...page, total: undefined, ...props.formData, ...times.value, month: undefined, type: 'unreceived' }).then(({ data }) => {
        tableData.value = formatNumber(data.data, notColumns)
        page.total = data.total
      })
    }

    const restore = id => {
      unreceivedRestore(id).then(({ data }) => {
        if (data.status === 200) {
          message.success('操作成功')
          init()
        }
      }).catch(error => message.error(error.response.data.message))
    }

    const close = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 300)
    }

    function handleCurrentChange(val) {
      page.page = val
      init()
    }

    function handleSizeChange(val) {
      page.page = 1
      page.pageSize = val
      init()
    }

    function filterMap(key, val) {
      const arr = mapEnum[key] || []
      const data = arr.find(item => item.value === val)

      return data ? data.label : val || '-'
    }

    onMounted(() => {
      setTimeout(() => {
        init()
      }, 100)
    })

    return {
      notColumns,
      tableData,
      show,
      close,
      restore,
      channelTypeMap,
      filterMap,

      page,
      handleCurrentChange,
      handleSizeChange,
    }
  },
}
</script>
<template>
  <vl-drawer
    :visible.sync="show"
    title="未到账列表"
    size="1000px"
    :before-close="close"
  >
    <div class="main">
      <div class="body">
        <AppView>
          <SuperTable
            :columns="notColumns"
            :data="tableData"
          >
            <template #actions="{ row }">
              <span v-auth="['ytAccountForm-restore']" class="app-link" @click="restore(row.id)">还原</span>
            </template>
            <template #channelType="{ row }">
              <span v-if="!row.channelSplitStatus">{{ filterMap('channelTypeOptions', row.channelType) }}</span>
              <span v-else> {{ filterMap('channelTypeOptions', row.channelType) }}(已拆) </span>
            </template>
            <template #channelName="{ row }">
              <div>{{ row.channelName }}</div>
              <div>
                {{ row.channelId }}
              </div>
            </template>
            <template #cms="{ row }">
              {{ filterMap('cmsOptions', row.cms) }}
            </template>
            <template #federalTax="{ row }">
              {{ row.federalTax }}%
            </template>
            <template #singaporeTax="{ row }">
              {{ row.singaporeTax }}%
            </template>
            <template #proportion="{ row }">
              {{ row.proportion }}%
            </template>
            <template #serviceCharge="{ row }">
              {{ row.serviceCharge }}%
            </template>
          </SuperTable>

          <template #footer>
            <Pagination
              :page-size="page.pageSize"
              :page-index="page.page"
              :total="page.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </template>
        </AppView>
      </div>
    </div>
  </vl-drawer>
</template>
<style scoped lang='scss'>
.main{
  background: #F6F7F8;
  height: calc(100%);
  .body{
    padding: 12px 20px;
    height: 100%;
  }
}
::v-deep .app-view__main{
  padding: 12px 20px !important;
}
::v-deep .vl-drawer__header{
  font-weight: 600;
  font-size: 18px;
  color: #000000;
}

</style>
