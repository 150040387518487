<script>
import { computed, ref, useSlots } from '@vue/composition-api'
import useAppConfig from '@core/@app-config/useAppConfig'
import { isFunction } from '@core/utils/is'
import empty from './image/empty.png'

export default {
  name: 'SuperTable',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '-',
    },
    emptyText: {
      type: String,
      default: '没有更多数据了',
    },
    height: {
      type: String,
      default: '',
    },
    range: {
      type: Boolean,
      default: false,
    },
    rowConfig: {
      type: Object,
      default: () => {},
    },
    checkboxConfig: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: String,
      default: '',
    },
    itemHeight: {
      type: Number,
      default: 60,
    },
  },
  setup(props, { emit }) {
    // 过滤隐藏数据
    const tableColumns = computed(() => props.columns.filter(item => !item.hidden))
    const { isDark } = useAppConfig()
    const slots = useSlots()
    const slotsKeys = Object.keys(slots)
    const tableRef = ref()

    // 过滤方法
    function format({ cellValue }, c) {
      // 存在格式化方法直接返回格式化处理方法
      if (isFunction(c.format)) {
        return c.format(cellValue)
      }

      // 存在枚举获取枚举
      if (c.enum && Array.isArray(c.enum)) {
        const item = c.enum.find(item => item.value === cellValue)

        return item ? item.label : props.placeholder
      }

      // 不存在枚举直接返回
      return (cellValue !== undefined && cellValue !== null) ? cellValue : props.placeholder
    }

    // 复选框选择
    const checkBoxChange = data => {
      const { checked, records, reserves } = data
      let selectionRows = []
      if (checked) {
        // 第一次选数据，还未进行翻页时
        if (reserves.length === 0) {
          selectionRows = records
        } else {
          // 数据集合，翻页存在已选中的数据时,拼接新选中的数据
          selectionRows = [...reserves, ...records]
        }
      } else {
        selectionRows = [...reserves, ...records]
      }
      emit('selection-change', selectionRows)

      emit('checkBoxChange', data)
    }
    // 复选框选择
    const selectAllEvent = data => {
      const { checked, records, reserves } = data

      let selectionRows = []

      // 全选中时
      if (checked) {
        // 第一次选数据，还未进行翻页时
        if (reserves.length === 0) {
          selectionRows = records
        } else {
          selectionRows = [...reserves, ...records]
        }
      } else {
        selectionRows = reserves
      }
      emit('selection-change', selectionRows)
      emit('selectAllEvent', data)
    }

    const clear = () => {
      tableRef.value.clearCheckboxRow()
      tableRef.value.clearCheckboxReserve()
      emit('selection-change', [])
    }

    function cellClickEvent({ row, column }) {
      emit('cellClick', { row, column })
    }

    return {
      tableColumns,
      slotsKeys,
      isDark,
      format,
      tableRef,
      empty,
      checkBoxChange,
      selectAllEvent,
      clear,
      cellClickEvent,
    }
  },
}
</script>

<template>
  <vxe-table
    ref="tableRef"
    :class="[isDark ? 'darkScrollbar': '', data.length === 0 ? 'no-data' : '' ]"
    :data="data"
    :header-row-class-name="isDark ? 'headerRowClass' : ''"
    :loading-config="{icon: 'vxe-icon-indicator roll', text: '加载中...'}"
    :row-config="{isCurrent: false, isHover: true , height:itemHeight , range,...rowConfig}"
    :height="height ? height : 'auto'"
    :max-height="maxHeight"
    show-overflow
    :loading="loading"
    :checkbox-config="checkboxConfig"
    v-bind="$attrs"
    v-on="$listeners"
    @checkbox-all="selectAllEvent"
    @checkbox-change="checkBoxChange"
    @cell-click="cellClickEvent"
  >
    <template v-for="item in tableColumns">
      <vxe-column
        :key="item.value"
        :field="item.props || item.value"
        :title="item.label || item.text"
        :align="item.align || 'left'"
        v-bind="item"
        :formatter="e => format(e, item)"
        :type="item.type"
      >
        <template
          v-if="slotsKeys.includes(item.props || item.value)"
          #default="{ row, rowIndex }"
        >
          <slot
            :column="item"
            :index="rowIndex"
            :name="item.props || item.value"
            :row="row"
          />
        </template>
      </vxe-column>
    </template>
    <template
      v-if="$slots.empty"
      #empty
    >
      <slot name="empty" />
    </template>
    <template
      v-else
      #empty
    >
      <div class="flex-vertical-center gap18">
        <img
          :src="empty"
          alt=""
          style="max-width: 125px;"
        />
        <div>{{ emptyText }}</div>
      </div>
    </template>
  </vxe-table>
</template>
<style lang='scss' scoped>
::v-deep .headerRowClass {
  background-color: #3b355a;
  color: #ffffff;
}

::v-deep .rowClass {
  background-color: #312d4b;
  color: #F6F7F8;

  &:hover {
    color: #28243d;
  }
}
::v-deep .vxe-table--header-wrapper {
  background: #FAFAFA;
}
</style>

<style lang="scss">
/*滚动条整体部分*/
.darkScrollbar ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/*滚动条的轨道*/
.darkScrollbar ::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
}
/*滚动条里面的小方块，能向上向下移动*/
.darkScrollbar ::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
}
.darkScrollbar ::-webkit-scrollbar-thumb:hover {
  background-color: rgba(168, 168, 168, 0.52);
}
.darkScrollbar ::-webkit-scrollbar-thumb:active {
  background-color: #787878;
}
/*边角，即两个滚动条的交汇处*/
.darkScrollbar ::-webkit-scrollbar-corner {
  background-color: #FFFFFF;
}
.vxe-table--border-line {
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
  z-index: 5 !important;
}
.no-data {
  .vxe-table--border-line {
    border-bottom: 0 !important;
  }
}

.vxe-checkbox:not(.is--disabled):hover .vxe-checkbox--icon, .vxe-custom--checkbox-option:not(.is--disabled):hover .vxe-checkbox--icon, .vxe-export--panel-column-option:not(.is--disabled):hover .vxe-checkbox--icon, .vxe-table--filter-option:not(.is--disabled):hover .vxe-checkbox--icon, .vxe-table--render-default .vxe-cell--checkbox:not(.is--disabled):hover .vxe-checkbox--icon{
  color: var(--v-primary-base);
}

.vxe-table--render-default .is--checked.vxe-cell--checkbox .vxe-checkbox--icon {
  color: var(--v-primary-base) !important;
}
.vxe-table--render-default .is--indeterminate.vxe-cell--checkbox .vxe-checkbox--icon {
  color: var(--v-primary-base) !important;
}
.vxe-table--render-default .is--checked.vxe-cell--checkbox {
  color: #606266 !important;
}
.vxe-table--render-default .is--indeterminate.vxe-cell--checkbox {
  color: #606266 !important;
}

.vxe-table--render-default .vxe-table--fixed-left-wrapper, .vxe-table--render-default .vxe-table--fixed-right-wrapper{
  z-index: 2 !important;
}

.vxe-table--fixed-right-wrapper .scrolling--middle{
  z-index: 2 !important;
}
</style>
