export const statusPointColorMap = {
  0: '#FFA80F', // 未生成 warning
  1: '#00B09B', // 已生成 success
  2: '#8d8d8d', // 无需生成 灰色
}

export const signStatusColorMap = {
  0: '#2CA0F8', // 待发起 info
  1: '#FFA80F', // 待签字 warning
  2: '#00B09B', // 已签字 success
  7: '#2CA0F8', // 待生成 info
  5: '#F24545', // 发起失败 error
}

export const checkStatusColorMap = {
  0: '#2CA0F8', // 待审核 info
  1: '#00B09B', // 审核通过 success
  '-1': '#F24545', // 已驳回 error
}

export const paymentStatusColorMap = {
  待汇款: '#2CA0F8', // 待审核 info
  已汇款: '#00B09B', // 审核通过 success
}

export const invoiceStatusColorMap = {
  0: '#2CA0F8', // 待上传 info
  1: '#2CA0F8', // 待审核 info
  2: '#00B09B', // 审核通过 success
  3: '#F24545', // 已驳回 error
  4: '#2CA0F8', // 待发起 info
  5: '#FFA80F', // 待签字 warning
  6: '#00B09B', // 已签字 success
  7: '#2CA0F8', // 待生成 info
  8: '#00B09B', // 已生成 success
  9: '#00B09B', // 已确认 success
  10: '#F24545', // 发起失败 error
}

export const uploadStatusColorMap = {
  0: '#2CA0F8', // 待上传 info
  1: '#00B09B', // 已上传 success
  2: '#F24545', // 上传失败 error
  3: '#FFA80F', // 处理中 warning
  4: '#8d8d8d', // 已取消 灰色
}

export const remittanceStatusColorMap = {
  0: '#2CA0F8', // 待汇款 info
  1: '#FFA80F', // 汇款中 warning
  2: '#00B09B', // 已汇款 success
  3: '#F24545', // 汇款失败 error
  4: '#8d8d8d', // 无需汇款 灰色
  5: '#F24545', // 提交失败 error
}
