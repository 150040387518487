<script>
import {
  defineComponent, ref, computed, onMounted, onUnmounted,
} from '@vue/composition-api'
import { videoSuffix, thumbnailSuffix, subtitleSuffix } from '@core/utils/options'
import { UPLOAD_STATUS_MAP } from '@core/utils/constant'
import { AliOSS } from '@core/utils/useAliOSS'
import { useMessage } from '@/hooks/useMessage'
import { getVideoData } from '@core/utils/dataProcess'
import { containsEmojiAndSpecialChars } from '@core/utils'
import { getUserData } from '@/auth/utils'
import config from '../../../../config'
// import VideoPreview from './VideoPreview.vue'
import ImagePreview from './imagePreview.vue'
import LinkPreview from './LinkPreview.vue'

const FILE_TYPE_MAP = {
  image: {
    accept: thumbnailSuffix.join(','),
    preview: 'ImagePreview',
  },
  subtitle: {
    accept: subtitleSuffix.join(','),
    preview: 'LinkPreview',
  },
  video: {
    accept: videoSuffix.join(','),
    // preview: 'VideoPreview',
  },
  all: {
    accept: '*',
    preview: 'LinkPreview',
  },
}

export default defineComponent({
  components: {
    // VideoPreview,
    ImagePreview,
    LinkPreview,
  },
  props: {
    bucket: {
      type: String,
      default: config.defaultBucket,
    },

    // 上传按钮配置
    btnConfig: {
      type: Object,
      default: () => ({
        text: '上传文件',
        icon: 'icon-a-shangchuan2x',
        iconColor: 'rgba(0, 0, 0, 0.85)',
      }),
    },

    // 文件类型image图片，video视频，subtitle srt文件，all 所有类型
    fileType: {
      type: String,
      default: 'all',
    },

    // 自定义可上传的文件类型
    acceptType: {
      type: String,
      default: null,
    },
    disabled: Boolean,

    // 开启预览
    showPreview: {
      type: Boolean,
      default: false,
    },

    // 预览形式
    previewType: {
      type: String,
      default: 'LinkPreview',
    },

    // 是否支持多文件
    multiple: {
      type: Boolean,
      default: false,
    },

    filesDetail: {
      type: Array,
      default: () => [],
    },

    // 是否显示进度条
    showProgressBar: {
      type: Boolean,
      default: true,
    },

    // 上传之前钩子函数
    beforeUpload: {
      type: Function,
      default: () => () => true,
    },

    // 是否需要校验文件名
    needCheckName: {
      type: Boolean,
      default: false,
    },

  },
  setup(props, { emit }) {
    const uploadRef = ref()
    const fileModel = ref()
    const filesChooseArr = ref([])
    const filesUploadedArr = ref([])
    const aliOss = new AliOSS(props.bucket)
    const isUploadCover = ref(false)
    const progress = computed(() => (isUploadCover.value ? { value: 100 } : aliOss.progressBar))
    const { message } = useMessage()
    const isShowProgressBar = ref(false)
    const filePath = ref('')
    const currUploadObj = ref({})
    const uploadStatus = ref(UPLOAD_STATUS_MAP.PENDING) // 0.未开始,1.进行中,2.暂停,3.失败,4.完成
    let uploadTask = null

    // 点击上传按钮
    const btnClick = () => {
      uploadRef.value.$refs.input.click()

      aliOss.fetchOSS()
    }

    // 获取文件唯一前缀
    const getPrefixPath = () => {
      const date = new Date()
      const { userId } = getUserData()

      return `finance/${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}/${userId}/${date.valueOf()}`
    }

    // 上传封面
    const uploadCover = (url, file) => {
      isUploadCover.value = true
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const fileName = `${getPrefixPath()}_cover-image.png`

          // 上传文件到OSS
          return aliOss.fileUploadMulti(fileName, blob)
        })
        .then(result => {
          emit('setCover', {
            key: result.name,
            bucket: result.bucket,
            size: file.size,
            name: file.name,
            filePath: file.filePath,
          })
        })
        .catch(error => {
          console.error(error)
        })
    }

    const uploadFile = async (file, countinueObj = {}) => {
      if (!file) return
      const fileDetail = {}

      const { size, name } = file

      fileDetail.size = size
      fileDetail.name = name
      fileDetail.type = props.fileType
      const { duration } = await getVideoData(file)
      fileDetail.duration = duration
      fileDetail.bucket = props.bucket
      const fileNameUpload = countinueObj.fileNameUpload ? countinueObj.fileNameUpload : `${getPrefixPath()}_${name}`

      currUploadObj.value = {
        fileNameUpload,
        file,
      }

      await aliOss.fileUploadMulti(fileNameUpload, file).then(() => {
        /* 判断文件是否上传成功(阿里云上是否有同名文件) */
        aliOss.list(fileNameUpload).then(async result => {
          if (result.objects.length) {
            /* 阿里云中存在同名文件（上传成功） */
            fileDetail.key = fileNameUpload

            message.success(`${name} 文件上传完成`)
            isShowProgressBar.value = false
            fileDetail.isPaused = false
            fileDetail.isUploaded = true
            fileDetail.filePath = await aliOss.fetchDownloadLink(fileDetail.key)
            // fileDetail.fileCover = await aliOss.fetchDownloadLinkCover(fileDetail.key)
            filePath.value = fileDetail.filePath
            filesUploadedArr.value.push(fileDetail)

            uploadStatus.value = UPLOAD_STATUS_MAP.SUCCESS

            // 更新
            emit('change', [fileDetail])

            uploadTask()
          } else {
            /* 在阿里云OSS中找不到对应文件，上传失败 */
            message.error(`${name} 上传失败`)
            isShowProgressBar.value = false
            fileDetail.isPaused = false
            uploadTask()
            uploadStatus.value = UPLOAD_STATUS_MAP.FAIL
          }
        }).catch(e => {
          /* 查询阿里云OSS中对应文件失败，也认为上传失败 */
          console.log(e)
          message.error(`${name} 上传失败`)
          isShowProgressBar.value = false
          fileDetail.isPaused = false
          uploadStatus.value = UPLOAD_STATUS_MAP.FAIL
          uploadTask()
        })
      }).catch(error => {
        console.log(error)

        // 取消上传
        if (error.name === 'cancel') return
        message.error(`${name} 上传失败`)
        isShowProgressBar.value = false
        fileDetail.isPaused = false
        uploadStatus.value = UPLOAD_STATUS_MAP.FAIL
        uploadTask()
      })
    }

    // 上传排队
    uploadTask = () => {
      if (filesChooseArr.value.length) {
        const file = filesChooseArr.value.pop()
        if (props.needCheckName && containsEmojiAndSpecialChars(file.name)) {
          message.error(`${file.name}，上传文件名不能含有特殊字符`)
          uploadStatus.value = UPLOAD_STATUS_MAP.PENDING

          return
        }

        isShowProgressBar.value = true
        uploadFile(file)
      } else {
        fileModel.value = null
      }
    }

    // 文件修改上传
    const changeFile = files => {
      // 判断上传之前判断
      if (typeof props.beforeUpload === 'function' && !props.beforeUpload(files)) {
        return
      }
      isUploadCover.value = false
      aliOss.progressBar.value = 0
      uploadStatus.value = UPLOAD_STATUS_MAP.ON

      // 判断是否为单文件
      if (props.multiple) {
        // 创建上传任务
        filesChooseArr.value = files
        uploadTask()
      } else {
        if (props.needCheckName && containsEmojiAndSpecialChars(files.name)) {
          message.error(`${files.name}，上传文件名不能含有特殊字符`)
          fileModel.value = null
          uploadStatus.value = UPLOAD_STATUS_MAP.PENDING

          return
        }
        isShowProgressBar.value = true
        uploadFile(files)
      }
    }

    // 单文件上传删除文件
    const uploadFileDelete = () => {
      message.error('成功删除文件')
      isShowProgressBar.value = false
      filePath.value = null
      fileModel.value = null
      uploadStatus.value = UPLOAD_STATUS_MAP.PENDING
      aliOss.fileUploadPause()
      currUploadObj.value = {}

      // aliOss.progressBar.value = 0
      emit('change', [])
    }

    // 暂停
    const handlePause = () => {
      aliOss.fileUploadPause()
      uploadStatus.value = UPLOAD_STATUS_MAP.STOP
    }

    // 继续
    const handleContinue = () => {
      uploadFile(currUploadObj.value.file, currUploadObj.value)
      uploadStatus.value = UPLOAD_STATUS_MAP.ON
    }

    const isLinkPreview = computed(() => props.previewType === 'LinkPreview')

    onMounted(() => {
      // 初始化预览
      if (!props.multiple && props.showPreview) {
        const [file] = props.filesDetail

        if (file && file.key) {
          filePath.value = aliOss.fetchDownloadLink(file.key)
        }
      }
    })

    onUnmounted(() => {
      aliOss.fileUploadPause()
    })

    return {
      fileModel,
      FILE_TYPE_MAP,
      progress,
      uploadRef,
      isShowProgressBar,
      filePath,
      isLinkPreview,
      filesUploadedArr,
      uploadStatus,
      uploadCover,
      currUploadObj,
      UPLOAD_STATUS_MAP,

      btnClick,
      changeFile,
      uploadFileDelete,
      handlePause,
      handleContinue,
    }
  },
})
</script>

<template>
  <div class="upload-box">
    <v-file-input
      ref="uploadRef"
      v-model="fileModel"
      name="file"
      :accept="acceptType ? acceptType : FILE_TYPE_MAP[fileType].accept"
      :disabled="disabled"
      outlined
      dense
      hide-details="auto"
      clearable
      :multiple="multiple"
      style="display: none"
      @change="changeFile"
    >
    </v-file-input>

    <v-btn
      v-if="!(showPreview && filePath && !isLinkPreview)"
      v-bind="$attrs"
      class="upload-btn"
      :class="disabled ? 'disabled': ''"
      outlined
      :disabled="disabled || uploadStatus === UPLOAD_STATUS_MAP.ON"
      @click="btnClick"
    >
      <span
        class="iconfont mr-1"
        :class="btnConfig.icon"
        :style="`color: ${btnConfig.iconColor}`"
      ></span>
      {{ btnConfig.text }}
    </v-btn>

    <div
      v-if="showPreview && filePath"
      :class="isLinkPreview ? 'link-preview':'file-prevew'"
    >
      <component
        :is="isLinkPreview ? 'LinkPreview': FILE_TYPE_MAP[fileType].preview"
        :src="filePath"
        :name="filesUploadedArr[0].name"
      />
      <span
        class="iconfont"
        :class="isLinkPreview ? 'icon-a-shanchu2x': 'icon-a-chacha2x'"
        @click="uploadFileDelete"
      ></span>
    </div>

    <!--单文件时展示上传进度-->
    <v-progress-linear
      v-if="isShowProgressBar && showProgressBar"
      :value="progress.value"
      height="12"
      class="mt-2"
    >
      {{ Math.ceil(progress.value) }}%
    </v-progress-linear>
  </div>
</template>
<style lang="scss" scoped>
.upload-box{
  width: 100%;
}
.upload-btn {
  border: 1px solid rgba(0, 0, 0, 0.10);
  color: rgba(0, 0, 0, 0.85);
  width: 116px;
  height: 40px;
  display: flex;
  border-radius: 6px;
  align-items: center;
  .iconfont{
    font-size: 14px;
  }
}
.upload-btn.disabled{
  .iconfont{
    opacity: 0.5;
  }
}
.link-preview{
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  background: #F6F7F8;
  padding: 0 12px;
  width: 100%;
  margin-top: 8px;
}
.file-prevew{
  width: 160px;
  height: 90px;
  position: relative;
  .iconfont{
    cursor: pointer;
  }
  .icon-a-chacha2x{
    position: absolute;
    top: -13px;
    right: -8px;
    z-index: 2;
    border-radius: 50%;
  }
}
::v-deep .v-progress-linear{
    width: 100%;
    height: 8px;
    border-radius: 6px;
    overflow: hidden;
    font-size: 12px;
    .v-progress-linear__background{
      background-color: rgba(246, 247, 248, 1)!important;
    }
    .v-progress-linear__buffer{
      display: none;
    }
  }
</style>
