export const TAG_CONFIG_ITEMS = [
  {
    bgColor: 'rgba(251, 151, 22, 0.06)',
    color: 'rgba(251, 151, 22, 1)',
    key: 'pending',
  },
  {
    bgColor: 'rgba(242,69,69,0.06)',
    color: 'rgba(242, 69, 69, 1)',
    key: 'error',
  },
  {
    bgColor: 'rgba(44, 160, 248, 0.06)',
    color: 'rgba(44, 160, 248, 1)',
    key: 'primary',
  },
  {
    bgColor: 'rgba(137, 146, 160, 0.06)',
    color: 'rgba(137, 146, 160, 1)',
    key: 'normal',
  },
  {
    bgColor: 'rgba(0, 176, 155, 0.06)',
    color: 'rgba(0, 176, 155, 1)',
    key: 'success',
  },
]

export const UPLOAD_STATUS_MAP = {
  PENDING: 0, // 未开始
  ON: 1, // 进行中
  STOP: 2, // 暂停
  FAIL: 3, // 失败
  SUCCESS: 4, // 成功
}

export const USER_TYPE = {
  SELF: 'self',
  AGENT: 'agent',
  AGENT_AND_MAKE: 'order',
}

export const adjustCurrencyMap = {
  人民币: '￥',
  美元: '$',
  undefined: '',
}

export const platformUnitMap = {
  proforma: '￥',
  vat: '￥',
  outsea: '$',
}
