import OSS from 'ali-oss'
import axios from '@axios'
import { ref } from '@vue/composition-api/dist/vue-composition-api'
import moment from 'moment'
import config from '../../../config'

let client = null
let abortCheckpoint = null
const progressBar = ref(0)

// 刷新临时访问凭证的时间间隔，单位为毫秒。
const refreshSTSTokenInterval = 300000
export const alioss = {
  region: '',
  accessKeyId: '',
  accessKeySecret: '',
  stsToken: '',
  bucket: '',
  refreshSTSTokenInterval,
}

const options = {
  // 获取分片上传进度、断点和返回值。
  progress: (p, cpt, res) => {
    console.log('p:', p * 100)
    console.log('cpt:', cpt)
    console.log('res:', res)
  },

  // 设置并发上传的分片数量。
  parallel: 4,

  // 设置分片大小。默认值为1 MB，最小值为100 KB。
  partSize: 1024 * 1024,

  // headers,
  // 自定义元数据，通过HeadObject接口可以获取Object的元数据。
  meta: { year: new Date().getFullYear(), people: 'test' },
  mime: 'text/plain',
}

export const progress = progressBar

function fetchToken(bucket) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${config.serviceUrl}/base/aliOSSToken?bucket=${bucket || config.defaultBucket}`)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

export class AliOSS {
  constructor(bucket) {
    this.client = null
    this.progressBar = ref(0)
    this.abortCheckpoint = null
    this.bucket = bucket || config.defaultBucket
    this.refreshSTSTokenInterval = 300000
    this.alioss = {
      region: '',
      accessKeyId: '',
      accessKeySecret: '',
      stsToken: '',
      bucket: '',
      refreshSTSTokenInterval: 300000,
    }
    this.options = {
      // 获取分片上传进度、断点和返回值。
      progress: (p, cpt, res) => {
        console.log('p:', p * 100)
        console.log('cpt:', cpt)
        console.log('res:', res)
      },

      // 设置并发上传的分片数量。
      parallel: 4,

      // 设置分片大小。默认值为1 MB，最小值为100 KB。
      partSize: 1024 * 1024,

      // headers,
      // 自定义元数据，通过HeadObject接口可以获取Object的元数据。
      meta: { year: new Date().getFullYear(), people: 'test' },
      mime: 'text/plain',
    }
  }

  async fetchOSS() {
    // 若token未过期则不需要重新获取
    if (this.client?.stsTokenFreshTime) {
      if (moment().isBefore(moment(this.client.stsTokenFreshTime).add(this.refreshSTSTokenInterval, 'ms'))) return
    }
    const response = await fetchToken(this.bucket)
    const { data } = response
    // console.log(data)
    this.alioss.region = data.region
    this.alioss.accessKeyId = data.credentials.accessKeyId
    this.alioss.accessKeySecret = data.credentials.accessKeySecret
    this.alioss.stsToken = data.credentials.securityToken
    this.alioss.bucket = data.bucket

    this.client = new OSS({
      // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
      region: this.alioss.region,

      // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
      accessKeyId: this.alioss.accessKeyId,
      accessKeySecret: this.alioss.accessKeySecret,

      // 从STS服务获取的安全令牌（SecurityToken）。
      stsToken: this.alioss.stsToken,

      // 填写Bucket名称。
      bucket: this.alioss.bucket,

      // 刷新临时访问凭证的时间间隔，单位为毫秒。
      refreshSTSTokenInterval: this.alioss.refreshSTSTokenInterval,

      // 刷新临时访问凭证。
      refreshSTSToken: async () => {
        const refreshToken = await fetchToken(this.bucket)

        return {
          accessKeyId: refreshToken.data.credentials.accessKeyId,
          accessKeySecret: refreshToken.data.credentials.accessKeySecret,
          stsToken: refreshToken.data.credentials.securityToken,
        }
      },
    })
    console.log(this.client)
  }

  fetchDownloadLink(fileKey, fileName, isDownload) {
    if (!fileKey) return ''

    /* 设置Content-Disposition头是为了以attachment形式打开文件链接从而下载文件，但似乎没什么用? */
    const response = {
      'content-disposition': `attachment; filename=${encodeURIComponent(fileName || fileKey)}`,
    }
    const downloadLink = this.client.signatureUrl(fileKey, {
      response: isDownload ? response : '',
      expires: 14400,
    })

    // console.log(downloadLink)

    // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
    return downloadLink
  }

  fileUploadMulti(fileKey, file) {
    return new Promise((resolve, reject) => {
      this.client.multipartUpload(fileKey, file, {
        // 为了实现断点上传，可以在上传过程中保存断点信息（checkpoint）。
        // 发生上传错误后，将已保存的checkpoint作为参数传递给multipartUpload，
        // 此时将从上次上传失败的地方继续上传。
        checkpoint: this.abortCheckpoint,

        // 设置上传回调
        progress: (p, cpt) => {
          // 为中断点赋值。
          this.abortCheckpoint = cpt

          // console.log(abortCheckpoint)

          // 文件上传成功时清除断点与进度条
          if (p === 1) {
            this.progressBar.value = 0
            this.abortCheckpoint = null
          }

          // 获取上传进度。
          this.progressBar.value = p * 100

          // console.log(res)
        },
      }).then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  fileUploadPause() {
    this.client?.cancel()
  }

  fileUploadCancel() {
    return new Promise((resolve, reject) => {
      this.client.abortMultipartUpload(this.abortCheckpoint.name, this.abortCheckpoint.uploadId, this.options)
        .then(response => {
          this.progressBar.value = 0
          this.abortCheckpoint = null

          return resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  /* 列出aliOss中的对应文件 */
  list(dir) {
    return new Promise((resolve, reject) => {
      this.client.list({
        prefix: dir,
      })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

export const fetchOSS = async bucket => {
  // 若token未过期则不需要重新获取
  if (client?.stsTokenFreshTime) {
    if (moment().isBefore(moment(client.stsTokenFreshTime).add(refreshSTSTokenInterval, 'ms'))) return
  }
  const response = await fetchToken(bucket)
  const { data } = response.data
  console.log(response)
  alioss.region = data.region
  alioss.accessKeyId = data.credentials.accessKeyId
  alioss.accessKeySecret = data.credentials.accessKeySecret
  alioss.stsToken = data.credentials.securityToken
  alioss.bucket = data.bucket

  client = new OSS({
    // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
    region: alioss.region,

    // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
    accessKeyId: alioss.accessKeyId,
    accessKeySecret: alioss.accessKeySecret,

    // 从STS服务获取的安全令牌（SecurityToken）。
    stsToken: alioss.stsToken,

    // 填写Bucket名称。
    bucket: alioss.bucket,

    // 刷新临时访问凭证的时间间隔，单位为毫秒。
    refreshSTSTokenInterval: alioss.refreshSTSTokenInterval,

    // 刷新临时访问凭证。
    refreshSTSToken: async () => {
      const refreshToken = await fetchToken(bucket)

      return {
        accessKeyId: refreshToken.data.data.credentials.accessKeyId,
        accessKeySecret: refreshToken.data.data.credentials.accessKeySecret,
        stsToken: refreshToken.data.data.credentials.securityToken,
      }
    },
  })
  console.log(client)
}

export const fetchDownloadLink = (fileKey, fileName) => {
  /* 设置Content-Disposition头是为了以attachment形式打开文件链接从而下载文件，但似乎没什么用? */
  const responseDownload = {
    'Content-Disposition': `attachment;filename=${encodeURIComponent(fileName || fileKey)}`,
  }
  const downloadLink = client.signatureUrl(fileKey, {
    response: {
      'content-disposition': `attachment; filename="${fileName || fileKey}"`,
    },
    expires: 14400,
  })
  console.log(downloadLink)

  // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
  return downloadLink
}

// 填写Object完整路径。Object完整路径中不能包含Bucket名称。
// 您可以通过自定义文件名（例如exampleobject.txt）或文件完整路径（例如exampledir/exampleobject.txt）的形式实现将数据上传到当前Bucket或Bucket中的指定目录。
// data对象可以自定义为file对象、Blob数据或者OSS Buffer。
export const fileUploadSimple = (fileKey, file) => new Promise((resolve, reject) => {
  client.put(fileKey, file)
    .then(response => response(response))
    .catch(error => reject(error))
})

export const fileUploadMulti = (fileKey, file) => new Promise((resolve, reject) => {
  client.multipartUpload(fileKey, file, {
    // 为了实现断点上传，可以在上传过程中保存断点信息（checkpoint）。
    // 发生上传错误后，将已保存的checkpoint作为参数传递给multipartUpload，
    // 此时将从上次上传失败的地方继续上传。
    checkpoint: abortCheckpoint,

    // 设置上传回调
    progress: (p, cpt) => {
      // 为中断点赋值。
      abortCheckpoint = cpt

      // console.log(abortCheckpoint)

      // 文件上传成功时清除断点与进度条
      if (p === 1) {
        progressBar.value = 0
        abortCheckpoint = null
      }

      // 获取上传进度。
      progressBar.value = p * 100

      // console.log(res)
    },
  }).then(response => resolve(response))
    .catch(error => reject(error))
})

export const fileUploadPause = () => {
  client?.cancel()
}

export const fileUploadCancel = () => new Promise((resolve, reject) => {
  client.abortMultipartUpload(abortCheckpoint.name, abortCheckpoint.uploadId, options)
    .then(response => {
      progressBar.value = 0
      abortCheckpoint = null

      return resolve(response)
    })
    .catch(error => reject(error))
})

export const fileDelete = fileKey => new Promise((resolve, reject) => {
  client.delete(fileKey)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

export const filesDelete = fileKeyArr => new Promise((resolve, reject) => {
  client.deleteMulti(fileKeyArr)
    .then(response => resolve(response))
    .catch(error => reject(error))
})

/* 列出aliOss中的对应文件 */
export const list = dir => new Promise((resolve, reject) => {
  client.list({
    prefix: dir,
  })
    .then(response => resolve(response))
    .catch(error => reject(error))
})
