<script>
import { formatNumber, addSpace } from '@/@core/utils'
import { downloadFile } from '@/@core/utils/form'
import Pagination from '@/components/Pagination.vue'
import { useMessage } from '@/hooks/useMessage'
import SuperTable from '@/components/SuperTable.vue'
import moment from 'moment'
import { ref, reactive, onMounted, nextTick, watch, computed } from '@vue/composition-api'
import AppView from '@/components/AppView.vue'
import SearchForm from '@/components/SearchForm/index.vue'
import mapEnum, { channelTypeMap } from '../mapEnum'
import { estimateList, estimateDelete, estimateExport } from '../service'
import { tableColumn1 } from '../tabCols'
import Subset from './subset.vue'

export default {
  name: 'Provisional',
  components: { AppView, SearchForm, SuperTable, Subset, Pagination },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    tab: {
      type: String,
      default: '',
    },
    activeName: {
      type: String,
      default: 'zg',
    },
  },
  setup(props) {
    const form = ref()
    const loading = ref(false)
    const { MessageBox, message } = useMessage()

    const searchForm = computed(() => props.formData).value

    const times = computed(() => ({
      monthStart: searchForm.month[0],
      monthEnd: searchForm.month[1],
    }))

    const page = reactive({
      total: 0,
      page: 1,
      pageSize: 20,
    })

    const revenueForm = reactive({
      type: 'estimate',
      id: undefined,
      channelId: undefined,
      title: undefined,
    })
    const searchConfig = ref([
      {
        el: 'DateMonthRange',
        props: 'month',
        label: '月份',
        type: 'month',
        clearable: false,
      },
      {
        el: 'VTextField',
        props: 'title',
        label: '频道名称',
      },
      {
        el: 'VTextField',
        props: 'channelId',
        label: '频道ID',
      },
      {
        el: 'VSelect',
        props: 'cms',
        label: '收款系统',
        items: mapEnum.cmsOptions,
        itemText: 'label',
        itemValue: 'value',
      },
      {
        el: 'VSelect',
        props: 'channelType',
        label: '频道类型',
        items: mapEnum.channelTypeOptions,
        itemText: 'label',
        itemValue: 'value',
      },
    ])
    const tableData = ref([])

    const visibleSubset = ref(false)

    const init = () => {
      loading.value = true
      estimateList({ ...page, total: undefined, ...searchForm, ...times.value, month: undefined }).then(({ data }) => {
        loading.value = false
        tableData.value = formatNumber(data.data, tableColumn1)
        page.total = data.total
      }).catch(error => {
        loading.value = false
        tableData.value = []
        page.total = 0
        message.error(error.response.data.message)
      })
    }
    const del = id => {
      MessageBox({
        message: '确认删除？',
        type: 'warning',
        showCancelButton: true,
        cancelBtnText: addSpace('取消'),
        confirmBtnText: addSpace('确定'),
      })
        .then(() => {
          estimateDelete(id)
            .then(({ data }) => {
              if (data.status === 200) message.success('删除成功')
              init()
            })
            .catch(error => message.error(error.response.data.message))
        })
        .catch(error => error)
    }

    const split = item => {
      visibleSubset.value = true
      revenueForm.id = item.id
      revenueForm.channelId = item.channelId
      revenueForm.title = item.channelName
    }

    const search = () => {
      page.page = 1
      init()
    }

    const reset = async () => {
      await form.value.resetForm()
      nextTick(() => {
        searchForm.month = [moment().subtract(1, 'month').format('yyyy-MM'), moment().subtract(1, 'month').format('yyyy-MM')]
        init()
      })
    }

    const exportExcel = () => {
      message.info('导出中...请稍等')
      estimateExport({ ...page, total: undefined, ...searchForm, ...times.value, month: undefined })
        .then(data => {
          const fileNameDownload = `在 ${new Date().toLocaleString().replaceAll('/', '-')}导出的暂估报表`
          downloadFile(data.data, fileNameDownload)
        })
        .catch(error => message.error(error.response.data.message))
    }

    const cellClick = () => {
      // if (column.field === 'channelName') {
      //   copyText(`
      //   ${row.channelName}，${row.channelId}`)
      //   message.success('复制成功')
      // }
    }

    function handleCurrentChange(val) {
      page.page = val
      init()
    }

    function handleSizeChange(val) {
      page.page = 1
      page.pageSize = val
      init()
    }

    function filterMap(key, val) {
      const arr = mapEnum[key] || []
      const data = arr.find(item => item.value === val)

      return data ? data.label : val || '-'
    }

    const subsetClose = () => {
      init()
      visibleSubset.value = false
    }
    onMounted(async () => {
      if (props.activeName === props.tab) {
        init()
      }
    })

    watch(
      () => searchForm.month,
      newVal => {
        searchForm.month = newVal
      },
    )

    return {
      searchConfig,
      searchForm,
      tableData,
      tableColumn1,
      reset,
      search,
      init,
      visibleSubset,
      exportExcel,
      del,
      split,
      page,
      revenueForm,
      handleCurrentChange,
      handleSizeChange,
      subsetClose,
      form,
      filterMap,
      channelTypeMap,
      cellClick,

      loading,
      addSpace,
    }
  },
}
</script>
<template>
  <AppView>
    <template #header>
      <div class="flex-vertical" style="width: 100%">
        <SearchForm ref="form" :columns="searchConfig" :value="searchForm" :search="search" :reset="reset" />
      </div>
    </template>
    <template #main-header>
      <v-btn v-auth="['ytAccountForm-estimate-export']" color="primary" @click="exportExcel">
        {{ addSpace('导出') }}
      </v-btn>
    </template>
    <SuperTable :columns="tableColumn1" :data="tableData" :loading="loading" @cellClick="cellClick">
      <template #channelName="{ row }">
        <div>{{ row.channelName }}</div>
        <div>{{ row.channelId }}</div>
      </template>
      <template #cms="{ row }">
        {{ filterMap('cmsOptions', row.cms) }}
      </template>
      <template #channelType="{ row }">
        <span v-if="!row.channelSplitStatus">{{ filterMap('channelTypeOptions', row.channelType) }}</span>
        <span v-else> {{ filterMap('channelTypeOptions', row.channelType) }}(已拆) </span>
      </template>
      <template #federalTax="{ row }">
        {{ row.federalTax ? `${row.federalTax}%`: '-' }}
      </template>
      <template #singaporeTax="{ row }">
        {{ row.singaporeTax ? `${row.singaporeTax}%`: '-' }}
      </template>
      <template #proportion="{ row }">
        {{ row.proportion ? `${row.proportion}%`: '-' }}
      </template>
      <template #serviceCharge="{ row }">
        {{ row.serviceCharge ? `${row.serviceCharge}%`: '-' }}
      </template>
      <template #actions="{ row }">
        <span v-if="row.channelType === channelTypeMap.subset" v-auth="['ytAccountForm-del-subset']" class="primary--text ml-5" @click="del(row.id)">删除</span>
        <span
          v-if="[channelTypeMap.singleOpen, channelTypeMap.compilation].includes(row.channelType)"
          v-auth="['ytAccountForm-subset']"
          class="primary--text ml-5"
          @click="split(row)"
        >拆分子集</span>
      </template>
    </SuperTable>

    <template #footer>
      <Pagination
        :page-size="page.pageSize"
        :page-index="page.page"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>

    <Subset v-if="visibleSubset" :form="revenueForm" @close="subsetClose" />
  </AppView>
</template>
<style scoped lang="scss">
span {
  cursor: pointer;
}

::v-deep .app-view__main {
  overflow: hidden;
}
</style>
