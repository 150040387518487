<script>
import Upload from '@/views/components/upload/Upload.vue'
import { onMounted, ref, reactive } from '@vue/composition-api'
import Dialog from '@/components/Dialog/index.vue'
import { useMessage } from '@/hooks/useMessage'
import { notColumns } from '../tabCols'

export default {
  name: 'BatchAdjustDialog',
  components: { Dialog, Upload },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const show = ref(true)

    const link = 'https://silverdawn-hz.oss-cn-hangzhou.aliyuncs.com/translateExcel/%E6%89%B9%E9%87%8F%E8%B0%83%E5%B7%AE%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx?OSSAccessKeyId=LTAI5tCbH26Eoiec58bofhLZ&Expires=2720001057&Signature=BQakOMAK1abp4sY5faFzu8efKk0%3D'
    const { message } = useMessage()
    const fileKey = reactive({})
    const fileInfoList = ref([
    ])
    const init = () => {

    }

    const close = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
        // emit('refresh')
      }, 300)
    }

    const handleFileChange = files => {
      const result = files.map(file => ({
        fileName: file.name,
        fileSize: file.size,
        filePath: file.key,
        bucketName: file.bucket,
        src: file.filePath,
      }))
      fileInfoList.value = result
    }

    const beforeUpload = file => {
      const chunkSize = 1024 * 1024 * 5
      const { size } = file

      if (size > chunkSize) {
        message.warning('文件大小超过5MB')

        return false
      }

      return true
    }

    const clickDownTemplate = () => {
      window.open(link)
      message.success('下载成功')
    }

    const delFile = (index, name) => {
      fileInfoList.value.splice(index, 1)
      message.success(`${name}已删除`)
    }

    const submit = () => {
      if (!fileInfoList.value.length) return message.warning('请上传文件')
      const key = fileInfoList.value[0].filePath
      emit('submit', key)
    }

    onMounted(() => {
      init()
    })

    return {
      notColumns,
      handleFileChange,
      show,
      close,
      submit,
      fileInfoList,
      clickDownTemplate,
      fileKey,
      delFile,
      beforeUpload,
    }
  },
}
</script>
<template>
  <Dialog
    v-model="show"
    title="批量调差"
    width="500px"
    show-footer
    confirm-btn-text="确认"
    @cancel="close"
    @confirm="submit"
  >
    <!-- <v-row class="content">
      <v-col cols="3">
        上传调差文件
      </v-col>
      <v-col cols="6">
        <Upload
          :btn-config="{
            text: '上传文件',
            icon: 'icon-a-shangchuan2x',
          }"
          :files-detail="[fileInfoList]"
          :accept-type="'.xls,.xlsx'"
          :before-upload="beforeUpload"
          @change="handleFileChange"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
      </v-col>
      <v-col cols="6">
        <div v-for="(item, index) in fileInfoList" :key="item.filePath" class="fileItem">
          <div>{{ item.fileName }}</div>
          <i class="iconfont icon-a-shanchu2x fs13 mr5" @click="delFile(index, item.fileName)"></i>
        </div>
      </v-col>
    </v-row> -->
    <div class="footer">
      <div>
        1、下载 <span class="download-link" @click="clickDownTemplate">&lt;批量调差模板&gt;</span>；
      </div>
      <div>2、按规则填写后，上传文件。</div>
    </div>

    <div class="mt10">
      <Upload
        :btn-config="{
          text: '上传文件',
          icon: 'icon-a-shangchuan2x',
        }"
        :files-detail="[fileInfoList]"
        :accept-type="'.xls,.xlsx'"
        :before-upload="beforeUpload"
        @change="handleFileChange"
      />
    </div>
    <div class="mt10">
      <div v-for="(item, index) in fileInfoList" :key="item.filePath" class="fileItem">
        <div>{{ item.fileName }}</div>
        <i class="iconfont icon-a-shanchu2x fs13 mr5" @click="delFile(index, item.fileName)"></i>
      </div>
    </div>
  </Dialog>
</template>
<style scoped lang='scss'>
.content{
  font-weight: 500;
  font-size: 14px;
  color: rgba(0,0,0,0.8);
  display: flex;
  align-items: center;
}
.fileItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f6f7f8;
    padding: 6px 12px;
    margin-bottom: 6px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    div{
      word-break: break-all;
    }
    &:last-child{
      margin-bottom:0;
    }
  }

.footer{
  font-weight: 400;
  font-size: 14px;
  color: rgba(0,0,0,0.6);
  div{
    line-height: 30px;
  }
}
</style>
