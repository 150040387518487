<script>
import DialogTable from '@/components/Dialog/DialogTable.vue'
import { onMounted, ref, computed } from '@vue/composition-api'
import Dialog from '@/components/Dialog/index.vue'
import { useMessage } from '@/hooks/useMessage'
import { downloadFile } from '@/@core/utils/form'
import { reversalExportFila } from '../service'
import { failHeader } from '../tabCols'

export default {
  name: 'ProgressLoading',
  components: { Dialog, DialogTable },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const show = ref(true)
    const tableData = computed(() => props.data.map(item => {
      const { channelIdError, cmsError, adjustError, channelIdIsExistError, cmsAndChannelIdError, channelSplitStatusError, settlementCreatedStatusError, cmsRevenueAdjustError, cmsRevenueUsAdjustError, cmsRevenueSgAdjustError, cmsRevenueAdjustRemarkError, dataRepeatError, cmsRevenueUsAdjustRemarkError, cmsRevenueSgAdjustRemarkError } = item

      return {
        rowNum: item.rowNum,
        reasonForFailure: `${channelIdError || ''}${cmsError || ''}${adjustError || ''}${channelIdIsExistError || ''}${cmsAndChannelIdError || ''}${channelSplitStatusError || ''}${settlementCreatedStatusError || ''}${cmsRevenueAdjustError || ''}${cmsRevenueUsAdjustError || ''}${cmsRevenueSgAdjustError || ''}${cmsRevenueAdjustRemarkError || ''}${dataRepeatError || ''}${cmsRevenueUsAdjustRemarkError || ''}${cmsRevenueSgAdjustRemarkError || ''}`,
      }
    }))

    const { message } = useMessage()
    const init = () => {

    }

    const close = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 300)
    }

    const submit = () => {
      close()
      emit('closeBatch')
    }

    const downLoadFile = () => {
      console.log('下载文件')
      reversalExportFila(tableData.value).then(data => {
        console.log(data)
        const fileNameDownload = `在 ${new Date().toLocaleString().replaceAll('/', '-')}导出的失败原因文件`
        downloadFile(data.data, fileNameDownload)
      }).catch(error => message.error(error.response.data.message))
    }

    onMounted(() => {
      init()
    })

    return {
      show,
      close,
      submit,
      downLoadFile,
      failHeader,
      tableData,
    }
  },
}
</script>
<template>
  <Dialog
    v-model="show"
    title="导入失败"
    width="500px"
    show-footer
    show-reject-btn-text
    :show-cancel-btn-text="false"
    confirm-btn-text="我知道了"
    reject-btn-text="下载文件"
    @cancel="close"
    @confirm="submit"
    @reject="downLoadFile"
  >
    <div class="text">
      导入失败，请修改后重新导入
    </div>
    <DialogTable
      class="mt12"
      :columns="failHeader"
      :data="tableData"
      :item-height="50"
      :is-hover="false"
      max-height="550px"
      min-height="50px"
    >
      <template #rowNum="{row}">
        {{ `第${row.rowNum || 0}行` }}
      </template>
    </DialogTable>
  </Dialog>
</template>
<style scoped lang='scss'>
.dialog-body{
  font-size: 14px !important;
  .text{
    color: rgba(0,0,0,0.85);
  }
}

::v-deep .vxe-table--main-wrapper{
  border: 1px solid #E7ECF0;
  border-bottom: none;
}
::v-deep .vxe-table--render-default.border--default .vxe-body--column{
  border-right:1px solid #E7ECF0;

  &:last-child{
    border-right:none;
  }
}
::v-deep .vxe-table--render-default.border--default .vxe-header--column{
  border-right:1px solid #E7ECF0;

  &:last-child{
    border-right:none;
  }
}

</style>
